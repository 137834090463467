import { styled } from 'styled-components';
import { device } from '../../../styles/stylesConfig';
import { useState } from 'react';
import { initialStateNewContact, initialStateNewStock } from '../../../utils/Jsons';
import { ActionScreenHeader } from '../../../components/ActionScreenHeader';
import { StockListView } from '../../../components/StockListView';
import { StockProps } from '../../../interfaces/StockInterfaces';
import { useSelector } from 'react-redux';
import { NewContactModal } from '../../../components/NewContactModal';
import { ContactsListView } from '../../../components/ContactsListView';
import { ContactProps } from '../../../interfaces/ContactInterfaces';
import { StoreProps } from '../../../interfaces/ReducerInterfaces';

const ScreenContainer = styled.div`
position: relative;
width: 100%;

    @media ${device.lg} {

        & > h1 {
        visibility: hidden;
        position: absolute;
        }
    }

    @media ${device.sm} {
        max-width: 80%;

    }
`

export const ContactsScreen = () => {

    const { contacts } = useSelector((state: StoreProps) => state.srandamios);

    const [newContactModal, setNewContactModal] = useState<{ visible: boolean, data: ContactProps }>({
        visible: false,
        data: initialStateNewContact
    });

    return (

        <ScreenContainer>

            <ActionScreenHeader
                title='Contactos'
                primaryButtonText='Agregar contacto'
                primaryButtonIcon={require('../../../images/plus-white.png')}
                primaryButtonAction={() => { setNewContactModal({ visible: true, data: initialStateNewContact }) }}
            />

            {
                newContactModal.visible && (
                    <NewContactModal
                        data={newContactModal.data}
                        setVisible={setNewContactModal}
                    />
                )
            }

            <ContactsListView
                data={contacts}
                setModalVisible={setNewContactModal}
            />

        </ScreenContainer>


    )
}
