import { styled } from "styled-components"
import { colors, device } from "../styles/stylesConfig"
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import { ProyectsMenuItem } from "./ProyectsMenuItem"
import { Typography } from "./Typography"
import { Icon } from "./Icon"

interface SectionsProyectMenuItemProps {
    itemName: string;
    icon: string;
    selected: boolean;
    goTo: string;
}

export interface ProyectMenuItemProps {
    itemName: string;
    icon: string;
    selected: boolean;
    goTo: string;
    subItems: SectionsProyectMenuItemProps[];
}

const MainContainer = styled.div`
    width: 20%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0px 10px 0px 0px;
    border: 1px solid ${colors.grey_normal};
    position: fixed;
    top: 70px;
    left: 0px;
    z-index: 2;

    @media ${device.lg} {
        min-width: 100%;
        height: auto;
        background-color: white;
        border-radius: 0px;
        border: none;
        border-bottom: 1px solid ${colors.grey_normal};
        box-shadow: 0px 3px 6px #00000026;
        position: fixed;
        top: 70px;
        left: 0px;

        overflow-x: auto;
        overflow-y: hidden;
        &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }
`

const HeadTitleContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 15px;
    padding-left: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid ${colors.grey_normal};
    visibility: visible;

    @media ${device.lg} {
        visibility: hidden;
        position: absolute;
    }
`

export const ProyectsMenu = () => {

    const menuList: ProyectMenuItemProps[] = [
        {
            itemName: 'SR Andamios',
            icon: require('../images/bigote.png'),
            goTo: '/proyects/2/works',
            selected: false,
            subItems: [
                {
                    itemName: 'Trabajos',
                    icon: require('../images/works-primary-dark.png'),
                    goTo: '/proyects/2/works',
                    selected: false,
                },
                {
                    itemName: 'Calendario',
                    icon: require('../images/calendar-primary-dark.png'),
                    goTo: '/proyects/2/calendar',
                    selected: false,
                },
                // {
                //     itemName: 'Contactos',
                //     icon: require('../images/contact-primary-dark.png'),
                //     goTo: '/proyects/2/contacts',
                //     selected: false,
                // },
                {
                    itemName: 'Stock',
                    icon: require('../images/stock-primary-dark.png'),
                    goTo: '/proyects/2/stock',
                    selected: false,
                },
                {
                    itemName: 'Presupuesto',
                    icon: require('../images/budget-primary-dark.png'),
                    goTo: '/proyects/2/budget',
                    selected: false,
                },

            ]
        },

    ]

    const location = useLocation();

    const [pathname, setPathname] = useState('');

    useEffect(() => {

        if (location.pathname.includes('/proyects/1')) {
            setPathname('/proyects/1');
        } else if (location.pathname.includes('/proyects/2')) {
            setPathname('/proyects/2');
        } else {
            setPathname('');
        }

    }, [location])

    const content = () => {

        return (
            <>

                <HeadTitleContainer>

                    <Icon size="M" src={require('../images/proyects.png')} />

                    <Typography size="16px" bold style={{ marginLeft: 10 }}>
                        Espacios de trabajo
                    </Typography>

                </HeadTitleContainer>

                {
                    menuList.map((item: ProyectMenuItemProps) => {

                        let isSelected = false;

                        if (item.itemName === 'Aluminios San Miguel' && pathname === '/proyects/1') {
                            isSelected = true;
                        } if (item.itemName === 'SR Andamios' && pathname === '/proyects/2') {
                            isSelected = true;
                        }

                        return (
                            <ProyectsMenuItem
                                key={item.itemName}
                                itemName={item.itemName}
                                icon={item.icon}
                                selected={isSelected}
                                subItems={item.subItems}
                                goTo={item.goTo}
                            />
                        )

                    })
                }

            </>
        )
    }

    return (

        <MainContainer>
            {content()}
        </MainContainer>
    )

}
