import { css, keyframes, styled } from "styled-components"
import { MenuItem, MenuItemProps } from "./MenuItem"
import { colors, device } from "../styles/stylesConfig"
import { Button } from "./atoms/Button"
import { useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { closeMenu } from "../actions/ui"

interface Props {
    type: 'desktop' | 'tablet/mobile'
    selected: 'Inicio' | 'Proyectos' | 'Perfil' | ''
}

const fadeIn = keyframes`
   0% {
      filter:blur(10px);
      transform: translateX(0) 
    } 100% {
      filter:blur(0px);
      transform: translateX(1000px)
      
    } 
`

const MenuDesktopContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 15%;
    min-height: 100%;
    background-color: rgba(222, 239, 254, 0.2);
    align-items: center;
    visibility: visible;

    @media ${device.lg} {
        visibility: hidden;
        width: 0%;
    }
`

const MenuMobileContainer = styled.div<{ appear: string }>`
    visibility: hidden;

    ${({ appear }) => appear === 'true' && css`
        animation: ${fadeIn} 250ms ease-in-out both; 
    `}
    
    @media ${device.lg} {
        visibility: visible;
        display: flex;
        flex-direction: column;
        width: 300px;
        height: 100%;
        background-color: white;
        border-right: 1px solid ${colors.border};
        box-shadow: -10px 0px 30px #000000;
        align-items: center;
        z-index: 2;
        transition: ${fadeIn} 250ms ease-in;
        position: fixed;
        left: -1000px;
        bottom: 0;
        padding-left: 20px;
        padding-right: 20px;

        & > button {
            width: 250px;

            &:hover {
                cursor: pointer;
                border-color: transparent;
                box-shadow: none;
                scale: none;
            }
        }

        & > button:first-child {
            position: absolute;
            top: 30px;
            right: 20px;
            width: auto;
        }

        @media ${device.sm} {
        width: 100%;
        border-right: none;
        box-shadow: none;
    }
    }
`

export const Menu = ({ type, selected }: Props) => {

    const menuList: MenuItemProps[] = [
        {
            itemName: 'SR Andamios',
            icon: require('../images/arrow-menu-primary-dark.png'),
            iconSelected: require('../images/arrow-menu-primary.png'),
            goTo: '/proyects/2/works',
            selected: false,
            isSubItem: true
        },

    ]

    const { menuBar } = useSelector((state: any) => state.ui);

    const dispatch = useDispatch();

    const [pathname, setPathname] = useState('');

    const location = useLocation();

    useEffect(() => {

        setPathname(location.pathname);

    }, [location])

    const content = () => {

        return (
            <>

                <img
                    className='general__logo'
                    src={require('../images/logo.png')}
                    alt='logo'
                    style={{ paddingTop: 100 }}
                />

                <MenuItem
                    itemName='Inicio'
                    icon={require('../images/home.png')}
                    iconSelected={require('../images/home-primary.png')}
                    goTo='/home'
                    selected={location.pathname === '/home'}
                    isSubItem={false}
                />

                <MenuItem
                    itemName='Perfil'
                    icon={require('../images/user.png')}
                    iconSelected={require('../images/user-primary.png')}
                    goTo='/profile'
                    selected={location.pathname.includes('/profile')}
                    isSubItem={false}
                />

                <MenuItem
                    itemName='Proyectos'
                    icon={require('../images/proyects.png')}
                    iconSelected={require('../images/proyects-primary.png')}
                    goTo='/proyects'
                    selected={location.pathname === ('/proyects')}
                    isSubItem={false}
                />

                {
                    menuList.map((item: MenuItemProps) => {

                        let isSelected = false;

                        if (item.itemName === 'Aluminios San Miguel' && pathname.includes('/proyects/1')) {
                            isSelected = true;
                        }
                        if (item.itemName === 'SR Andamios' && pathname.includes('/proyects/2')) {
                            isSelected = true;
                        }

                        return (
                            <MenuItem
                                key={item.itemName}
                                itemName={item.itemName}
                                icon={item.icon}
                                iconSelected={item.iconSelected}
                                selected={isSelected}
                                isSubItem={item.isSubItem}
                                goTo={item.goTo}
                            />
                        )

                    })
                }

            </>
        )
    }

    return type === "desktop" ? (
        <MenuDesktopContainer>

            {content()}


        </MenuDesktopContainer>
    )
        : (
            <MenuMobileContainer appear={menuBar.toString()}>

                <Button
                    type="terciary"
                    icon={require('../images/close-primary.png')}
                    text="CERRAR"
                    onClick={() => {
                        dispatch(closeMenu());
                    }}
                />

                {content()}


            </MenuMobileContainer>
        )

}
