import { styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Fragment } from "react";
import { Typography } from "./Typography";
import { Icon } from "./Icon";
import { Button } from "./atoms/Button";
import { ActionButtonsProps, ViewButtonsProps, WorkDetailViewsTypes, WorkViewsTypes } from "../interfaces/WorkInterfaces";

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${device.sm} {
        flex-direction: column;
        justify-content: center;
    }
`

const ViewsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    border-bottom: 1px solid ${colors.grey_normal};
    padding-bottom: 10px;

    @media ${device.sm} {
        border-bottom: none;
        padding-bottom: 0px;
    }
`

const OptionButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    overflow-x: scroll;
    white-space: nowrap;
    padding-bottom: 15px;

    /* &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      } */

    & > button {
        height: 30px;
        margin-right: 20px;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

    }

    @media ${device.md} {
        & > button:nth-child(1) {
            min-width: 260px;
            white-space: normal !important;
            height: fit-content !important;
        }

        & > button:nth-child(2) {
            min-width: 200px;
            white-space: normal !important;
            height: fit-content !important;
        }

        & > button:nth-child(3) {
            min-width: 180px;
            white-space: normal !important;
            height: fit-content !important;
        }
    }
    
`

const ItemButton = styled.button<{ selected: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    margin: 0px 10px;
    border: 3px solid transparent;
    border-radius: 4px;
    background-color: ${props => props.selected ? colors.info_light : 'transparent'};

    &:hover:enabled {
        cursor: pointer;
        border: 3px solid ${colors.info};
        box-shadow: 0px 0px 6px #00000026;
    }

    & > h1 {
        color: ${props => props.selected ? colors.primary : colors.primary_dark};
        margin-left: 10px;
    }

    &:first-of-type {
        margin-left: 0px;
    }

    &:last-of-type {
        margin-right: 0px;
    }


`

const Divisor = styled.hr`
    height: 20px;
    border: none;
    border:  1px solid ${colors.grey_normal};

    &:hover {
        cursor: pointer;
        border: 1px solid ${colors.info};
        box-shadow: 0px 0px 6px #00000026;        
    }

    &:last-of-type {
        visibility: hidden;
    }

`

interface Props {
    viewButtonsData: ViewButtonsProps[]
    actionButtonsData: ActionButtonsProps[]
    viewSelected: WorkViewsTypes | WorkDetailViewsTypes;
    setViewSelected: React.Dispatch<React.SetStateAction<WorkViewsTypes>>,
    onClickNewWork: () => void;
}

export const OptionWorkButtons = ({ viewButtonsData, actionButtonsData, viewSelected, setViewSelected, onClickNewWork }: Props) => {

    return (

        <MainContainer>

            <ViewsContainer>

                {
                    viewButtonsData.map((value) => {

                        const { id, name, icon, iconSelected, } = value

                        const selected = viewSelected === name;

                        return (

                            <Fragment key={id}>

                                <ItemButton selected={selected} key={id} disabled={selected} onClick={() => {
                                    localStorage.setItem('viewSelected', name);
                                    setViewSelected(name as WorkViewsTypes)
                                }}>

                                    <Icon size="S" src={selected ? iconSelected : icon} style={{ width: 16 }} />

                                    <Typography size="14px" bold={selected}>
                                        {name}
                                    </Typography>

                                </ItemButton>

                                <Divisor />

                            </Fragment>
                        )
                    })
                }



            </ViewsContainer>

            <OptionButtonsContainer>

                {
                    actionButtonsData.map((value) => {

                        const { id, name, icon, type, finished, styles, handleClick } = value


                        return (

                            <Button
                                key={id}
                                type={type}
                                text={name}
                                icon={!finished ? icon : undefined}
                                disabled={finished}
                                style={
                                    type === 'primary'
                                        ?
                                        {
                                            height: 30, marginTop: 0, backgroundColor: (finished || name === 'Total cobrado') ? colors.ok : undefined, color: 'white',
                                            borderRadius: 4, fontWeight: 600, textTransform: 'uppercase', fontSize: 12,
                                            ...styles
                                        }
                                        :
                                        { marginTop: 0, fontSize: 14, fontWeight: 500, color: colors.primary_dark, ...styles }
                                }
                                onClick={() => { handleClick() }}
                            />

                        )
                    })
                }

            </OptionButtonsContainer>

        </MainContainer>

    )
}