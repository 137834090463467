import { styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Typography } from "./Typography";
import { Button } from "./atoms/Button";
import { showMixinToast, showModalConfirm } from "../utils/Alerts";
import { StockProps } from "../interfaces/StockInterfaces";
import { LoadingComponent } from "./LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { WorkProps } from "../interfaces/WorkInterfaces";
import { finishLoading, setError, startLoading } from "../actions/ui";
import axios from "axios";
import { endpoints } from "../helpers/FirebaseHelper";
import { getWorksListSorted } from "../utils/Utilities";
import { login } from "../actions/auth";
import { getAuth } from "firebase/auth";
import { ContactProps } from "../interfaces/ContactInterfaces";
import { StoreProps } from "../interfaces/ReducerInterfaces";

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    position: relative;

    & > h1 {
        width: 100%;    
        text-align: start;
        margin-bottom: 10px;
        margin-left: 15px;

        @media ${device.sm} {
        text-align: center;
        margin-left: 0px;
    }
    }
`

const GroupContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    border: 1px solid ${colors.grey_normal};
    border-radius: 5px;
    position: relative;
    overflow-x: scroll;
    white-space: nowrap;
    border: 1px solid #ddd;
    
    @media ${device.sm} {
        width: 100vw;
        justify-content: center;
        border-left: none;
        border-right: none;
        border-radius: 0px;
    }
`

const ItemsContainer = styled.div`
    min-width: 100%;
    display: grid;
    grid-template-columns: repeat(7, minmax(165px, 1fr));
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.grey_normal};

    &:first-of-type {
        padding: 0px;
    }

    &:last-of-type {
        border-bottom: 1px solid transparent;
    }
`

const BoxData = styled.div`
    width: 100%;
    height: 30px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & > button {
        margin-right: 10px;
    }

    & > button:last-of-type {
        margin-right: 0px;
    }
`

interface Props {
    data: ContactProps[];
    setModalVisible: React.Dispatch<React.SetStateAction<{
        visible: boolean;
        data: ContactProps;
    }>>
}

export const ContactsListView = ({ data, setModalVisible }: Props) => {

    const { checkingContacts } = useSelector((state: StoreProps) => state.srandamios);

    const { name, token } = useSelector((state: any) => state.auth);

    const dispatch = useDispatch();

    return (

        <MainContainer>

            <GroupContainer>

                {
                    !checkingContacts ? (

                        <>

                            <ItemsContainer>

                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Apodo</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Nombre y apellido</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Teléfono</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Ocupación</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Documento</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Correo electrónico</Typography></BoxData>
                                <BoxData style={{ backgroundColor: colors.grey_light }}><Typography size='14px' bold>Acciones</Typography></BoxData>

                            </ItemsContainer>

                            {
                                data.length === 0 ? (

                                    <Typography size='16px' style={{ marginTop: 20, marginBottom: 15, textAlign: 'center', width: '100%' }}>No existen contactos cargados</Typography>

                                ) : (

                                    data.map((value) => {

                                        const { id, nickname, fullname, doc, phone, email, ocupation } = value


                                        return (

                                            <ItemsContainer key={id}>

                                                <BoxData><Typography size='14px'>{nickname}</Typography></BoxData>
                                                <BoxData><Typography size='14px'>{fullname ?? '-'}</Typography></BoxData>
                                                <BoxData><Typography size='14px'>{phone ?? '-'}</Typography></BoxData>
                                                <BoxData><Typography size='14px'>{ocupation ?? '-'}</Typography></BoxData>
                                                <BoxData><Typography size='14px'>{doc ?? '-'}</Typography></BoxData>
                                                <BoxData><Typography size='14px'>{email ?? '-'}</Typography></BoxData>
                                                <BoxData>

                                                    <Button
                                                        type="action"
                                                        tooltip="Modificar"
                                                        icon={require('../images/edit.png')}
                                                        onClick={() => {

                                                            setModalVisible({
                                                                visible: true,
                                                                data: value
                                                            });

                                                        }}
                                                    />

                                                    {
                                                        id &&
                                                        <Button
                                                            type="action"
                                                            tooltip="Eliminar"
                                                            icon={require('../images/delete-primary-dark.png')}
                                                            onClick={() => {

                                                                const handleDelete = async () => {

                                                                    dispatch(startLoading())

                                                                    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.deleteStock}`,
                                                                        { collection: "Contacts", id: id.toString() },
                                                                        { headers: { Authorization: `Bearer ${token}` } })
                                                                        .then(() => {

                                                                            dispatch(finishLoading())

                                                                            showMixinToast('Contacto eliminado', 'success')

                                                                        }).catch(async (e) => {

                                                                            dispatch(finishLoading())

                                                                            let msg = ''

                                                                            if (e.response !== undefined) {

                                                                                if (e.response.data.trace.includes('auth/id-token-expired')) {

                                                                                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                                                                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                                                                                } else {
                                                                                    msg = e.response.data.message
                                                                                }

                                                                            } else {
                                                                                msg = 'Ha ocurrido un error.'
                                                                            }

                                                                            console.error({ msg, error: e });

                                                                            await axios.post(
                                                                                `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                                                                                from: 'ERROR SR ANDAMIOS',
                                                                                to: process.env.REACT_APP_SEND_EMAIL_TO,
                                                                                name: 'Yo',
                                                                                phone: '',
                                                                                text: { name, msg, error: JSON.stringify(e, null, 2) }
                                                                            })

                                                                            dispatch(setError(msg));


                                                                        })

                                                                    dispatch(finishLoading())

                                                                }

                                                                showModalConfirm({
                                                                    title: '¿Estas seguro?',
                                                                    description: 'No vas a poder revertir esta acción.',
                                                                    icon: 'warning',
                                                                    confirmButtonText: 'Si, estoy seguro',
                                                                    handleConfirm: () => { handleDelete() }
                                                                })


                                                            }}
                                                        />

                                                    }

                                                </BoxData>

                                            </ItemsContainer>
                                        )
                                    })

                                )

                            }

                        </>

                    ) :
                        (
                            <LoadingComponent />
                        )
                }





            </GroupContainer>


        </MainContainer>

    )
}