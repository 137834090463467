import { useSelector } from 'react-redux';
import { styled } from "styled-components"
import { device } from '../../styles/stylesConfig';
import { PrimaryScreensContainer } from '../../components/PrimaryScreensContainer';
import { Typography } from '../../components/Typography';
import { ButtonCards } from '../../components/ButtonCards';

export const ButtonCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    position: relative;
    margin-top: 50px;

    & > h1 {
        position: absolute;
        top: -25px;
        left: 0px;

        @media ${device.md} {
        font-size: 16px !important;
        right: 0px;
        
        } 

        @media ${device.sm} {
            font-size: 14px !important;
        } 
    }

    @media ${device.xs} {
        flex-direction: column;
    }
`

export interface OptionType {
    value: string;
    label: string;
    description: string;
}

export const HomeScreen = () => {

    const { name } = useSelector((state: any) => state.auth);

    const directAccessButtons = [
        {
            text: 'SR Andamios',
            icon: require('../../images/bigote.png'),
            goTo: '/proyects/2/works'
        }
    ]

    return (

        <PrimaryScreensContainer type='primary' menuSelected='Inicio'>

            <Typography
                size='28px'
                bold
            >
                {`¡Hola ${name}, te damos la bienvenida a tu gestor de tareas!`}
            </Typography>

            <ButtonCardsContainer>

                <Typography
                    size='18px'
                >
                    Tus accesos directos:
                </Typography>

                {
                    directAccessButtons.map((button) => (
                        <ButtonCards
                            icon={button.icon}
                            text={button.text}
                            key={button.text}
                            goTo={button.goTo}
                        />
                    ))
                }

            </ButtonCardsContainer>

        </PrimaryScreensContainer>



    )
}
