import { css, styled } from "styled-components";
import { Typography } from "./Typography";
import { colors, device } from "../styles/stylesConfig";
import { Input } from "./Input";
import { Button } from "./atoms/Button";
import { useState } from "react";
import { showMixinToast } from "../utils/Alerts";
import { sortStock } from "../utils/Utilities";
import { StockProps, WorkStockProps } from "../interfaces/StockInterfaces";
import { WorkProps } from "../interfaces/WorkInterfaces";
import { useSelector } from "react-redux";
import { LoadingComponent } from "./LoadingComponent";
import { initialStateNewWorkStock } from "../utils/Jsons";
import { ContactProps } from "../interfaces/ContactInterfaces";
import { Checkbox } from "./Checkbox";

const SelectedStockItemContainer = styled.button<{ custom: string }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    padding: 30px 10px;
    outline: none;
    border: 1px solid transparent;
    border-bottom: 1px solid ${colors.grey_normal};
    background-color: white;
    text-align: left;
    cursor: pointer;
    transition: all ease-in-out 0.3s;

    &:last-of-type {
        border-bottom: 1px solid transparent;
    }

    &:hover {
        background-color: ${colors.info_light};
        border: 1px solid ${colors.info};
        border-radius: 8px;

    }

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        color: ${colors.grey_dark};
    }

    @media ${device.sm} {
        ${props => props.custom === 'true' && css`
            flex-direction: column;
            margin-top: 20px;
            padding-bottom: 30px;
            padding-left: 0px;
            padding-right: 0px;
        `}
    }
 
`

const SelectedStockData = styled.div<{ custom: string, showamounts: string }>`
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & > h1 {
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${colors.grey};
        margin-top: 5px;
    }

    @media ${device.sm} {
        ${props => props.custom === 'true' && css`
            flex-direction: column;
            align-items: center;
            justify-content: center;

        `}
    }
`

const SelectedStockNameContainer = styled.div<{ custom: string }>`
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & > h1 {
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${colors.grey};
        margin-top: 5px;
    }

    & > h1:first-of-type {
        color: ${colors.grey};
        color: ${colors.grey_dark};
        margin-top: 0px;
    }

    @media ${device.sm} {
        ${props => props.custom === 'true' && css`
            flex-direction: row;
            align-items: center;
            justify-content: center;

            & > h1:last-of-type {
                margin-top: 0px;
                margin-left: 10px;
            }
        `}
    }
`

const SelectedStockActions = styled.div<{ custom: string }>`
    display: flex;
    flex-direction: row;

    & > div {
        margin-right: 20px;
    }

    @media ${device.sm} {
        ${props => props.custom === 'true' && css`
            margin-top: 40px;

            & > div {
                margin-right: 20px;
            }
        `}
    }

    @media ${device.xs} {
        ${props => props.custom === 'true' && css`
        flex-direction: column;

            & > div {
                width: 100% !important;
                margin-right: 0px;
                margin-bottom: 40px;
            }
        `}
    }
`

interface Props {
    showAmounts: boolean;
    showDelete: boolean;
    disabled: boolean;
    extension?: boolean;
    data: ContactProps;
    form: ContactProps | null;
    setForm: React.Dispatch<React.SetStateAction<ContactProps | null>>
}

export const SelectedContactItem = ({ showAmounts, data: contact, form, setForm }: Props) => {

    const { id, nickname, fullname, phone } = contact;

    const [customSelectedAmount, setcustomSelectedAmount] = useState(false);

    const handleClickOption = () => {

        setForm(contact);
    }

    return (

        <SelectedStockItemContainer key={id} custom={customSelectedAmount.toString()} onClick={() => { handleClickOption() }}>

            <SelectedStockData custom={customSelectedAmount.toString()} showamounts={showAmounts.toString()}>

                <SelectedStockNameContainer custom={customSelectedAmount.toString()}>

                    <Typography size="14px">
                        {nickname}
                    </Typography>

                    <Typography size="12px">
                        {fullname ?? ' '}
                    </Typography>

                    <Typography size="12px">
                        {phone ?? ' '}
                    </Typography>

                </SelectedStockNameContainer>


            </SelectedStockData>

            <SelectedStockActions custom={customSelectedAmount.toString()}>


                <Checkbox
                    text=""
                    checked={form?.id === id}
                    key={id}
                    onClick={() => { }}
                />


            </SelectedStockActions>

        </SelectedStockItemContainer>

    )
}
