import { styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Typography } from "./Typography";
import { Button } from "./atoms/Button";
import { formatExpirationTime, getFullDate, getStateTitleDaysLeft, getWorkContact } from "../utils/Utilities";
import { WorkProps } from "../interfaces/WorkInterfaces";
import { getAuth } from "firebase/auth";
import { login } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { ContactButton } from "./ContactButton";
import { ContactProps } from "../interfaces/ContactInterfaces";
import { initialStateNewContact } from "../utils/Jsons";
import { StoreProps } from "../interfaces/ReducerInterfaces";
import { showMixinToast, showModalConfirm } from "../utils/Alerts";
import { startcreateUpdateContactQuery } from "../queries/ContactQueries";
import { startcreateUpdateWorkQuery } from "../queries/WorkQueries";
import { finishLoading, setError, startLoading } from "../actions/ui";
import axios from "axios";
import { endpoints } from "../helpers/FirebaseHelper";
import { PaymentStatus } from "./atoms/PaymentStatus";

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    position: relative;

    & > h1 {
        width: 100%;    
        text-align: start;
        margin-bottom: 10px;
        margin-left: 15px;

        @media ${device.sm} {
        text-align: center;
        margin-left: 0px;
    }
    }
`

const GroupContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    border: 1px solid ${colors.grey_normal};
    padding: 15px;
    border-radius: 5px;
    position: relative;
    overflow-x: scroll;
    white-space: nowrap;
    border: 1px solid #ddd;
    
    @media ${device.sm} {
        width: 100vw;
        justify-content: center;
        border-left: none;
        border-right: none;
        border-radius: 0px;
    }
`

const ItemsContainer = styled.div`
    min-width: 100%;
    display: grid;
    gap: 5px;
    grid-template-columns: 70px repeat(3, minmax(300px, 1fr));
    padding: 2.5px 0px;

    &:last-of-type {
        padding-bottom: 0px;
    }
`

const BoxData = styled.button`
    width: 100%;
    height: 35px;
    background-color: ${colors.grey_light};
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    border: none;
    cursor: pointer;
    position: relative;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

interface Props {
    title: string;
    color: string;
    worksList: WorkProps[];
    setWorkDetailModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setWorkDetailSelected: React.Dispatch<React.SetStateAction<WorkProps>>;
    setNewContactModal: React.Dispatch<React.SetStateAction<{
        visible: boolean;
        data: ContactProps;
    }>>;
    setReplaceContactModal: React.Dispatch<React.SetStateAction<{
        visible: boolean;
        data: WorkProps;
    }>>
}

export const WorksView = ({ title, color, worksList, setWorkDetailModalVisible, setWorkDetailSelected, setNewContactModal, setReplaceContactModal }: Props) => {

    const { contacts } = useSelector((state: StoreProps) => state.srandamios);

    const { name, token } = useSelector((state: StoreProps) => state.auth);

    const dispatch = useDispatch();

    const handleClick = async (value: WorkProps) => {

        setWorkDetailSelected(value);
        setWorkDetailModalVisible(true);

        const auth = getAuth();

        const tokenResult = await auth.currentUser!.getIdTokenResult(true)

        dispatch(login({
            uid: auth.currentUser!.uid,
            name: auth.currentUser!.displayName,
            token: tokenResult.token,
            expirationTime: formatExpirationTime(tokenResult.expirationTime)
        }))

    }

    return (

        <MainContainer>

            <Typography
                size='20px'
                bold
                color={color}
            >
                {title}
            </Typography>

            <GroupContainer>

                <ItemsContainer>

                    <BoxData style={{ backgroundColor: 'transparent', cursor: 'default' }}></BoxData>
                    <BoxData style={{ backgroundColor: 'transparent', cursor: 'default' }}><Typography size='14px' bold>Cliente</Typography></BoxData>
                    <BoxData style={{ backgroundColor: 'transparent', cursor: 'default' }}><Typography size='14px' bold>Dirección</Typography></BoxData>
                    <BoxData style={{ backgroundColor: 'transparent', cursor: 'default' }}><Typography size='14px' bold>Estado</Typography></BoxData>

                </ItemsContainer>

                {
                    worksList.map((value, index) => {

                        const { id, client, address, state, clientId } = value

                        return (

                            <ItemsContainer key={id}>

                                <BoxData style={{ backgroundColor: 'transparent' }}>
                                    <Button
                                        text="Abrir"
                                        type="terciary"
                                        icon={require('../images/open-primary.png')}
                                        style={{ fontSize: 14, fontWeight: "normal" }}
                                        onClick={() => handleClick(value)}
                                    />
                                </BoxData>
                                {/* <BoxData style={{ borderLeft: `2px solid ${color}`, backgroundColor: index % 2 === 0 ? colors.border : undefined, cursor: 'default' }}>

                                    {clientId && getWorkContact(contacts, clientId) ? (

                                        <>
                                            <ContactButton
                                                type='contact'
                                                onClick={() => {
                                                    setNewContactModal({ visible: true, data: getWorkContact(contacts, clientId)! })
                                                }}
                                                text={getWorkContact(contacts, clientId)?.nickname}
                                            />

                                            <ContactButton
                                                type='replace'
                                                onClick={() => {
                                                    setReplaceContactModal({
                                                        visible: true,
                                                        data: value
                                                    })
                                                }}
                                                text={client}
                                            />

                                        </>

                                    ) : (

                                        <>

                                            <Typography size='14px'>{client}</Typography>

                                            <ContactButton
                                                type='add'
                                                onClick={() => {

                                                    showModalConfirm({
                                                        icon: 'question',
                                                        title: `${client !== '' ? `"${client.toUpperCase()}"` : 'Atención'}`,
                                                        description: '¿Qué acción desea realizar?',
                                                        confirmButtonText: 'Agregar como nuevo contacto',
                                                        cancelButtonText: 'Seleccionar contacto existente',
                                                        confirmButtonColor: colors.primary,
                                                        cancelButtonColor: colors.primary_dark,
                                                        handleConfirm: async () => {

                                                            try {


                                                                const newId = parseInt(getFullDate());

                                                                await startcreateUpdateContactQuery({ id: newId, nickname: client }, token!, dispatch, name!)


                                                                await startcreateUpdateWorkQuery({ ...value, clientId: newId }, token!, dispatch, name!)

                                                                await showMixinToast('Contacto creado', 'success');

                                                            } catch (e: any) {

                                                                dispatch(finishLoading())

                                                                let msg = ''

                                                                if (e.response !== undefined) {

                                                                    if (e.response.data.trace?.includes('auth/id-token-expired')) {

                                                                        msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                                                            "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                                                                    } else {
                                                                        msg = e.response.data.message
                                                                    }

                                                                } else {
                                                                    msg = 'Ha ocurrido un error.'
                                                                }

                                                                console.error({ msg, error: e });

                                                                await axios.post(
                                                                    `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                                                                    from: 'ERROR SR ANDAMIOS',
                                                                    to: process.env.REACT_APP_SEND_EMAIL_TO,
                                                                    name: 'Yo',
                                                                    phone: '',
                                                                    text: { name, msg, error: JSON.stringify(e, null, 2) }
                                                                })

                                                                dispatch(setError(msg));

                                                            }

                                                        },
                                                        handleConfirmSecondary: () => {

                                                            setReplaceContactModal({
                                                                visible: true,
                                                                data: value
                                                            })
                                                        },
                                                    })

                                                }}
                                                text={client}
                                            />

                                        </>

                                    )
                                    }

                                </BoxData > */}

                                <BoxData onClick={() => handleClick(value)} style={{ borderLeft: `2px solid ${color}`, backgroundColor: index % 2 === 0 ? colors.grey_normal : undefined, }}>
                                    <PaymentStatus status={value.payment.filter((v) => { return !v.payed }).length === 0} />
                                    <Typography size='14px' color={client === '' ? colors.grey : colors.primary_dark}>{client === '' ? '( Vacío )' : client}</Typography>
                                </BoxData >
                                <BoxData onClick={() => handleClick(value)} style={{ backgroundColor: index % 2 === 0 ? colors.grey_normal : undefined }}>
                                    <Typography size='14px' color={address === '' ? colors.grey : colors.primary_dark}>{address === '' ? '( Vacío )' : address}</Typography>
                                </BoxData >
                                <BoxData onClick={() => handleClick(value)} style={{ backgroundColor: state.color }}><Typography size='14px' bold color="white">{state.name === 'Para entregar' || state.name === 'Para retirar' ? getStateTitleDaysLeft(value) : state.name}</Typography></BoxData>

                            </ItemsContainer>
                        )
                    })
                }

            </GroupContainer>

        </MainContainer>

    )
}