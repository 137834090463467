import { css, styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Button } from "./atoms/Button";
import { Input } from "./Input";
import { formatDateText, formatDateYYYYmmDD, formatPrice, getFullDate, getNextHalfPastHour, setDeliveryRetirementDateTextField, setLabelHourText } from "../utils/Utilities";
import { showMixinToast, showModalConfirm } from "../utils/Alerts";
import { HourFieldTypes, InvoiceTypes, PaymentInstanceTypes, PaymentMethodTypes, PaymentProps, TimeTypes, WorkProps } from "../interfaces/WorkInterfaces";
import { hoursJson } from "../utils/Jsons";
import { useSelector } from "react-redux";



const Container = styled.div<{ payed: string }>`
    min-height: fit-content;
    width: 100%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 10px;
    position: relative;
    background-color: white;
    border: 1px solid ${colors.grey_normal};

    & > h1 {
        position: absolute;
        top: -40px;
        left: 20px;
    }

    ${props => props.payed === 'true' && css`
        border: 1px solid ${colors.ok};
        background-color: ${colors.ok_light};
    `}

`

const SubContainer = styled.div<{ workdetail: string }>`
    width: 100%;
    min-height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    & > div {
        margin-left: 10px;
        margin-right: 10px;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

        @media ${device.md} {
            margin-left: 0px;
            margin-right: 0px;
        } 
        
    }

    @media ${device.md} {
        flex-direction: column;
    } 

    ${props => props.workdetail === 'true' && css`
        flex-direction: column;

        & > div {
            margin-left: 0px;
            margin-right: 0px;
        }

    `}
`

const ActionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;


    @media ${device.md} {
        flex-direction: column;
        width: 100%;

        & > button {
            margin-left: 0px !important;
        }

        & > button:nth-child(2) {
            margin-top: 20px !important;
        }
    } 
`

interface Props {
    dataItem: PaymentProps;
    form: WorkProps;
    setForm: React.Dispatch<React.SetStateAction<WorkProps>>;
    fieldIncomplete?: any;
    setFieldIncomplete?: React.Dispatch<React.SetStateAction<any>>
    readOnly?: boolean
}

export const NewPaymentItem = ({ dataItem, form, fieldIncomplete, readOnly, setForm, setFieldIncomplete }: Props) => {

    const { name } = useSelector((state: any) => state.auth);

    return (

        <Container payed={dataItem.payed.toString()}>

            <SubContainer workdetail={readOnly ? 'true' : ''}>

                <Input
                    type="number"
                    label={`Precio ($${formatPrice(dataItem.price)})`}
                    required
                    value={dataItem.price > 0 ? dataItem.price : ''}
                    placeholder="Valor del alquiler"
                    incomplete={(dataItem.price > 0 || !fieldIncomplete) ? false : fieldIncomplete.price}
                    readOnly={readOnly}
                    onChange={(e) => {

                        if (setFieldIncomplete && fieldIncomplete) { setFieldIncomplete({ ...fieldIncomplete, price: false }); }

                        let paymentFiltered: PaymentProps[] = form.payment.filter((value) => { return value.id !== dataItem.id });

                        setForm({ ...form, payment: [...paymentFiltered, { ...dataItem, price: e.target.value.trim() === '' ? 0 : parseInt(e.target.value) }] });
                    }}
                />

                <Input
                    label="Método de pago"
                    type="select"
                    required
                    value={dataItem.paymentMethod}
                    readOnly={readOnly}
                    onChange={(e) => {

                        let paymentFiltered: PaymentProps[] = form.payment.filter((value) => { return value.id !== dataItem.id });

                        setForm({ ...form, payment: [...paymentFiltered, { ...dataItem, paymentMethod: e.target.value as PaymentMethodTypes }] });
                    }}
                >
                    <option value={'Efectivo' as PaymentMethodTypes}>Efectivo</option>
                    <option value={'Mercado Pago' as PaymentMethodTypes}>Mercado Pago</option>
                    <option value={'Transferencia bancaria' as PaymentMethodTypes}>Transferencia bancaria</option>
                    <option value={'Dólares' as PaymentMethodTypes}>Dólares</option>
                    <option value={'Cheque físico' as PaymentMethodTypes}>Cheque físico</option>
                    <option value={'Echeq' as PaymentMethodTypes}>Echeq</option>
                </Input>

                <Input
                    label="Facturación"
                    type="select"
                    value={dataItem.invoicing}
                    required
                    readOnly={readOnly}
                    onChange={(e) => {

                        let paymentFiltered: PaymentProps[] = form.payment.filter((value) => { return value.id !== dataItem.id });

                        setForm({ ...form, payment: [...paymentFiltered, { ...dataItem, invoicing: e.target.value as InvoiceTypes }] });
                    }}
                >
                    <option value={'Sin factura' as InvoiceTypes}>Sin factura</option>
                    <option value={'Factura A' as InvoiceTypes}>Factura A</option>
                    <option value={'Factura B' as InvoiceTypes}>Factura B</option>
                    <option value={'Factura C' as InvoiceTypes}>Factura C</option>
                </Input>

            </SubContainer>


            <SubContainer workdetail={readOnly ? 'true' : ''}>

                <Input
                    label="Instancia de cobro"
                    type="select"
                    value={dataItem.paymentInstance}
                    readOnly={readOnly}
                    required
                    onChange={(e) => {

                        let paymentFiltered: PaymentProps[] = form.payment.filter((value) => { return value.id !== dataItem.id });

                        setForm({
                            ...form,
                            payment: [
                                ...paymentFiltered, {
                                    ...dataItem,
                                    paymentInstance: e.target.value as PaymentInstanceTypes
                                }]
                        });

                    }}
                >
                    <option value={'Al momento de la entrega' as PaymentInstanceTypes}>Al momento de la entrega</option>
                    <option value={'Al momento del retiro' as PaymentInstanceTypes}>Al momento del retiro</option>
                    <option value={'Indicar fecha y hora' as PaymentInstanceTypes}>Indicar fecha y hora</option>
                </Input>

                {
                    dataItem.paymentInstance === 'Indicar fecha y hora' && (

                        <Input
                            type="date"
                            label="Fecha de cobro"
                            value={`${readOnly ? `${formatDateText(dataItem.dateTime.date)} ${setDeliveryRetirementDateTextField(dataItem.dateTime)}` : `${dataItem.dateTime.date > 0 ? formatDateYYYYmmDD(dataItem.dateTime.date) : ''}`}`}
                            incomplete={fieldIncomplete ? fieldIncomplete.deliveryDate : false}
                            readOnly={readOnly}
                            required
                            onChange={(e: any) => {

                                const date = parseInt(e.currentTarget.value.replace(/-/g, ''));

                                let paymentFiltered: PaymentProps[] = form.payment.filter((value) => { return value.id !== dataItem.id });

                                setForm({ ...form, payment: [...paymentFiltered, { ...dataItem, dateTime: { ...dataItem.dateTime, date } }] });

                            }}
                        />
                    )
                }

            </SubContainer>

            {
                dataItem.paymentInstance === "Indicar fecha y hora" && (

                    <SubContainer workdetail={readOnly ? 'true' : ''}>

                        <Input
                            label="Horario"
                            type="select"
                            value={dataItem.dateTime.hourType}
                            readOnly={readOnly}
                            required
                            onChange={(e) => {

                                let paymentFiltered: PaymentProps[] = form.payment.filter((value) => { return value.id !== dataItem.id });

                                setForm({
                                    ...form,
                                    payment: [
                                        ...paymentFiltered,
                                        {
                                            ...dataItem,
                                            dateTime: {
                                                ...dataItem.dateTime,
                                                hourType: e.target.value as HourFieldTypes,
                                                hourStart: e.target.value === 'Antes de las...' ? '08:00hs' : dataItem.dateTime.hourStart,
                                                hourEnd: e.target.value === 'Después de las...' ? '20:00hs' : getNextHalfPastHour(dataItem.dateTime.hourStart as TimeTypes),
                                            }
                                        }
                                    ]
                                });

                            }}
                        >
                            <option value={'Horario específico' as HourFieldTypes}>Horario específico</option>
                            <option value={'Antes de las...' as HourFieldTypes}>Antes de las...</option>
                            <option value={'Después de las...' as HourFieldTypes}>Después de las...</option>
                            <option value={'Intervalo de horas' as HourFieldTypes}>Intervalo de horas</option>
                        </Input>

                        {
                            (dataItem.dateTime.hourType !== "Antes de las...") && (
                                <Input
                                    label={setLabelHourText(dataItem.dateTime.hourType)}
                                    type="select"
                                    required
                                    readOnly={readOnly}
                                    value={dataItem.dateTime.hourStart}
                                    onChange={(e) => {

                                        let paymentFiltered: PaymentProps[] = form.payment.filter((value) => { return value.id !== dataItem.id });

                                        setForm({
                                            ...form,
                                            payment: [
                                                ...paymentFiltered,
                                                {
                                                    ...dataItem,
                                                    dateTime: {
                                                        ...dataItem.dateTime,
                                                        hourStart: e.target.value as TimeTypes,
                                                        hourEnd: dataItem.dateTime.hourType === 'Después de las...' ? '20:00hs' : getNextHalfPastHour(e.target.value as TimeTypes),
                                                    }
                                                }
                                            ]
                                        });
                                    }}
                                >
                                    {
                                        hoursJson.map((value) => {

                                            return <option key={value} value={value as TimeTypes}>{value}</option>
                                        })
                                    }

                                </Input>
                            )
                        }

                        {
                            (dataItem.dateTime.hourType === 'Intervalo de horas' || dataItem.dateTime.hourType === 'Antes de las...') && (
                                <Input
                                    label={dataItem.dateTime.hourType === 'Antes de las...' ? dataItem.dateTime.hourType : "Hasta las..."}
                                    type="select"
                                    required
                                    readOnly={readOnly}
                                    value={dataItem.dateTime.hourEnd}
                                    onChange={(e) => {

                                        let paymentFiltered: PaymentProps[] = form.payment.filter((value) => { return value.id !== dataItem.id });

                                        setForm({
                                            ...form,
                                            payment: [
                                                ...paymentFiltered,
                                                {
                                                    ...dataItem,
                                                    dateTime: {
                                                        ...dataItem.dateTime,
                                                        hourStart: dataItem.dateTime.hourType === 'Antes de las...' ? '08:00hs' : dataItem.dateTime.hourStart,
                                                        hourEnd: e.target.value as TimeTypes,
                                                    }
                                                }
                                            ]
                                        });
                                    }}
                                >
                                    {
                                        hoursJson.map((value) => {

                                            return <option key={value} value={value as TimeTypes}>{value}</option>
                                        })
                                    }

                                </Input>
                            )
                        }

                    </SubContainer>

                )
            }

            {
                !readOnly && (

                    <ActionContainer style={{ marginTop: 30, marginBottom: 30, justifyContent: 'right' }}>

                        <Button
                            type={dataItem.payed ? 'secondary' : "primary"}
                            text={dataItem.payed ? "Deshacer cobro" : "Marcar como cobrado"}
                            icon={dataItem.payed ? require('../images/deshacer.png') : require('../images/check-white.png')}
                            style={{ marginTop: 0, height: 35, backgroundColor: dataItem.payed ? "white" : colors.primary }}
                            onClick={() => {

                                let samePay: PaymentProps = form.payment.find((value) => { return value.id === dataItem.id }) ?? dataItem;
                                let paymentFiltered: PaymentProps[] = form.payment.filter((value) => { return value.id !== dataItem.id });

                                setForm({
                                    ...form,
                                    payment: [
                                        ...paymentFiltered, {
                                            ...dataItem,
                                            payed: !samePay.payed,
                                            responsiblePayed: !samePay.payed ? name : null,
                                            datePayed: !samePay.payed ? parseInt(getFullDate()) : null,
                                        }]
                                });

                                if (!dataItem.payed) {
                                    showMixinToast('Cobro indicado con éxito', 'success')
                                } else {
                                    showMixinToast('Se ha deshecho el cobro', 'info')
                                }

                            }}
                        />

                        {
                            form.payment.length > 1 && (

                                <Button
                                    type="secondary"
                                    text="Eliminar"
                                    icon={require('../images/delete-primary-dark.png')}
                                    style={{ marginTop: 0, height: 35, borderColor: colors.danger, backgroundColor: colors.danger_light, paddingLeft: 20, paddingRight: 20, marginLeft: 20 }}
                                    onClick={() => {

                                        showModalConfirm({
                                            title: '¿Estas seguro?',
                                            description: 'Estas a punto de eliminar este método de cobro.',
                                            icon: 'warning',
                                            confirmButtonText: 'Si, estoy seguro',
                                            handleConfirm: () => {

                                                let paymentFiltered: PaymentProps[] = form.payment.filter((value) => { return value.id !== dataItem.id });

                                                setForm({
                                                    ...form,
                                                    payment: paymentFiltered
                                                });

                                                showMixinToast('Cobro eliminado', 'success')
                                            }
                                        })
                                    }}
                                />

                            )
                        }


                    </ActionContainer>

                )
            }

        </Container>

    )
}
