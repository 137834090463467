import { css, styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Typography } from "./Typography";
import { Button } from "./atoms/Button";
import { OptionWorkButtons } from "./OptionWorkButtons";
import { ActionButtonsProps, ActivityProps, CommentProps, FieldLocationPathTypes, ViewButtonsProps, WorkProps } from "../interfaces/WorkInterfaces";
import { useEffect, useState } from "react";
import { BoxContainer } from "./BoxContainer";
import { Icon } from "./Icon";
import { formatDateText, getFullDate, sortActivity, sortPayment, setDeliveryRetirementDateTextField, filterExtensionStock, getWorkContact, formatFullDateTimeAsTZ, getIfDeliveryTodayExists, getDate, formatDateDDmmYYYY } from '../utils/Utilities';
import { ActivityItem } from "./ActivityItem";
import { CommentItem } from "./CommentItem";
import { Input } from "./Input";
import { useDispatch, useSelector } from "react-redux";
import { initialStateNewComment, initialStateNewContact, initialStateNewStock, initialStateNewWork } from "../utils/Jsons";
import { showMixinToast, showModalConfirm } from "../utils/Alerts";
import { NewWorkModal } from "./NewWorkModal";
import { ExtendDaysModal } from "./ExtendDaysModal";
import { finishLoading, setError, startLoading } from "../actions/ui";
import { SetPayedModal } from "./SetPayedModal";
import { SelectedStockItem } from "./SelectedStockItem";
import { NewPaymentItem } from "./NewPaymentItem";
import axios from "axios";
import { StockProps } from "../interfaces/StockInterfaces";
import { endpoints } from "../helpers/FirebaseHelper";
import { ContactButton } from "./ContactButton";
import { StoreProps } from "../interfaces/ReducerInterfaces";
import { startcreateUpdateContactQuery } from "../queries/ContactQueries";
import { startcreateUpdateWorkQuery } from "../queries/WorkQueries";
import { ContactProps } from "../interfaces/ContactInterfaces";
import { NewContactModal } from "./NewContactModal";
import { SelectContactListModal } from "./SelectContactListModal";
import { differenceInCalendarDays } from "date-fns";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 90%;
    max-width: 95%;
    max-height: 90%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 50px;
    padding-bottom: 0px;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;

    @media (max-width: 1000px) {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0px;
    }

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 50px;
    margin-bottom: 50px;

    @media ${device.md} {
        grid-template-columns: repeat(1, 1fr);
    }

    & > div:first-child {
        min-width: 400px;

        @media ${device.md} {
            min-width: auto;
        }
    }

    @media ${device.sm} {
        display: flex;
        flex-direction: column;
        grid-template-columns: none;
    }
`

const DetailsContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    grid-column-start: 1;
    grid-column-end: 2;

    @media ${device.xl} {
        grid-template-columns: repeat(1, 1fr);
    }

    @media ${device.md} {
        grid-column-start: 1;
        grid-column-end: 2;
    }

    & > div:nth-child(1)
    & > div:nth-child(2) {
        min-width: 400px;

        @media ${device.md} {
            min-width: auto;
        }
    }

    @media ${device.sm} {
        display: flex;
        flex-direction: column;
        grid-template-columns: none;
        padding-bottom: 50px;
    }

`

const SubcontentContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    grid-column-start: 2;
    grid-column-end: 4;

    @media ${device.xl} {
        grid-template-columns: repeat(1, 1fr);
    }

    @media ${device.md} {
        grid-column-start: 1;
        grid-column-end: 2;
    }

    & > div:nth-child(1)
    & > div:nth-child(2) {
        min-width: 400px;

        @media ${device.md} {
            min-width: auto;
        }
    }

    @media ${device.sm} {
        display: flex;
        flex-direction: column;
        grid-template-columns: none;
        padding-bottom: 50px;
    }

`

const DetailTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px;
    padding: 0px 5px;
`

const InputCommentContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    margin-top: 25px;
`

const SectionContainer = styled.div<{ fieldincomplete: string }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    border-bottom: 1px solid ${colors.border};

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${colors.grey_dark};
        margin-top: 30px;
        padding-bottom: 30px;
    }

    ${props => props.fieldincomplete === 'true' && css`
        border-bottom: 1px solid ${colors.danger};
    `}
`

const SectionHeaderContainer = styled.div<{ fieldincomplete: string }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.border};
    padding-bottom: 10px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        color: ${colors.grey_dark};
    }

    & > button {
        color: ${colors.grey_dark};
    }

    ${props => props.fieldincomplete === 'true' && css`
        border-bottom: 1px solid ${colors.danger};
    `}
`

interface Props {
    data: WorkProps;
    worksList: WorkProps[];
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WorkDetailModal = ({ data, worksList, setVisible }: Props) => {

    const { contacts } = useSelector((state: StoreProps) => state.srandamios);

    const { name, token } = useSelector((state: StoreProps) => state.auth);

    const { stock: stockList, checkingStock, checkingWorks } = useSelector((state: any) => state.srandamios);

    const dispatch = useDispatch();

    const [newCommentForm, setNewCommentForm] = useState<CommentProps>(initialStateNewComment);

    const [commentFormIncomplete, setCommentFormIncomplete] = useState<boolean>(false);

    const [editWorkModalVisible, setEditWorkModalVisible] = useState<boolean>(false);

    const [extendDaysModalVisible, setExtendDaysModalVisible] = useState<boolean>(false);

    const [setPayedModalVisible, setSetPayedModalVisible] = useState<boolean>(false);

    const [workDetail, setWorkDetail] = useState<WorkProps>(data);

    const workDetailViewsButtonsData: ViewButtonsProps[] = [
        {
            id: 1,
            name: 'Información general',
            icon: require('../images/gral-info-primary-dark.png'),
            iconSelected: require('../images/gral-info-primary.png'),
        },
    ]

    const setButtonStateText = () => {

        switch (workDetail.state.name) {
            case 'A confirmar':
                return 'Confirmar trabajo'
            case 'Para entregar':
                return 'Marcar como entregado'
            case 'Para retirar':
                return 'Marcar como retirado'
            case 'Falta cobrar':
                return 'Marcar como cobrado'
            case 'Terminado':
                return 'Terminado'
        }

    }

    const setButtonStateAction = () => {

        const handleUpdateWorkList = async () => {

            dispatch(startLoading());

            let work = workDetail;

            let activityObj: ActivityProps = {
                id: parseInt(getFullDate()),
                date: parseInt(getFullDate()),
                creator: name!,
                text: 'ha realizado un cambio de estado'
            };

            switch (workDetail.state.name) {
                case 'A confirmar':
                    work = { ...workDetail, state: { id: 1, name: 'Para entregar', color: colors.primary } };
                    activityObj = { ...activityObj, changeState: { stateFrom: { id: 0, name: 'A confirmar', color: colors.to_confirm }, status: 'changed', stateTo: { id: 1, name: 'Para entregar', color: colors.primary } } };
                    break;
                case 'Para entregar':
                    work = { ...workDetail, state: { id: 2, name: 'Para retirar', color: colors.warning } };
                    activityObj = { ...activityObj, changeState: { stateFrom: { id: 1, name: 'Para entregar', color: colors.primary }, status: 'changed', stateTo: { id: 2, name: 'Para retirar', color: colors.warning } } };
                    break;
                case 'Para retirar':

                    if (workDetail.payment.filter((value) => { return !value.payed }).length === 0) {

                        work = { ...workDetail, state: { id: 4, name: 'Terminado', color: colors.ok }, payment: [...workDetail.payment.map((value) => { return { ...value, payed: true } })] };
                        activityObj = { ...activityObj, changeState: { stateFrom: { id: 3, name: 'Falta cobrar', color: colors.danger }, status: 'changed', stateTo: { id: 4, name: 'Terminado', color: colors.ok } } };
                        break;
                    } else {

                        work = { ...workDetail, state: { id: 3, name: 'Falta cobrar', color: colors.danger } };
                        activityObj = { ...activityObj, changeState: { stateFrom: { id: 2, name: 'Para retirar', color: colors.warning }, status: 'changed', stateTo: { id: 3, name: 'Falta cobrar', color: colors.danger } } };
                        break;

                    }

                case 'Falta cobrar':
                    work = { ...workDetail, state: { id: 4, name: 'Terminado', color: colors.ok }, payment: [...workDetail.payment.map((value) => { return { ...value, payed: true } })] };
                    activityObj = { ...activityObj, changeState: { stateFrom: { id: 3, name: 'Falta cobrar', color: colors.danger }, status: 'changed', stateTo: { id: 4, name: 'Terminado', color: colors.ok } } };
                    break;
                case 'Terminado':
                    break;
            }

            await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createWork}`,
                { collection: "Works", id: work.id.toString(), doc: { ...work, activity: sortActivity([...workDetail.activity, activityObj]) } },
                { headers: { Authorization: `Bearer ${token}` } })
                .then(() => {

                    dispatch(finishLoading())

                    setWorkDetail({ ...work, activity: sortActivity([...workDetail.activity, activityObj]) });

                    showMixinToast('Estado actualizado', 'success');

                }).catch(async (e) => {

                    dispatch(finishLoading())

                    let msg = ''

                    if (e.response !== undefined) {

                        if (e.response.data.trace.includes('auth/id-token-expired')) {

                            msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                        } else {
                            msg = e.response.data.message
                        }

                    } else {
                        msg = 'Ha ocurrido un error.'
                    }

                    console.error({ msg, error: e });

                    await axios.post(
                        `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                        from: 'ERROR SR ANDAMIOS',
                        to: process.env.REACT_APP_SEND_EMAIL_TO,
                        name: 'Yo',
                        phone: '',
                        text: { name, msg, error: JSON.stringify(e, null, 2) }
                    })

                    dispatch(setError(msg));

                })

            dispatch(finishLoading())

        }

        const setTitleConfirm = () => {

            switch (workDetail.state.name) {
                case 'A confirmar':
                    return 'Estas a punto de confirmar el trabajo. Esto implica que se descontarán los productos del stock y la tarea pasará al estado PARA ENTREGAR.'
                case 'Para entregar':
                    return 'Estas a punto de indicar que se entregaron los productos. La tarea pasará al estado PARA RETIRAR.'
                case 'Para retirar':

                    if (workDetail.payment.filter((value) => { return !value.payed }).length === 0)
                        return 'Estas a punto de indicar que se retiraron los productos. Esto implica que se agregaran nuevamente al stock disponible. Debido a que se cobró el monto total registrado, la tarea pasará al estado TERMINADO.'
                    else
                        return 'Estas a punto de indicar que se retiraron los productos. Esto implica que se agregaran nuevamente al stock disponible. La tarea pasará al estado FALTA COBRAR.'

                case 'Falta cobrar':
                    return 'Estas a punto de indicar que se cobró el valor total de la tarea. La tarea pasará al estado TERMINADO.'
                default:
                    return ''
            }

        }

        showModalConfirm({
            title: '¿Estas seguro?',
            description: setTitleConfirm(),
            icon: 'warning',
            confirmButtonText: 'Confirmar',
            confirmButtonColor: colors.ok,
            cancelButtonColor: colors.danger,
            handleConfirm: () => { handleUpdateWorkList() }
        })

    }

    const setExtensionButtonAction = () => {

        const handleUpdateWorkList = async () => {

            dispatch(startLoading());

            let activityObj: ActivityProps = {
                id: parseInt(getFullDate()),
                date: parseInt(getFullDate()),
                creator: name!,
                text: 'ha indicado que se entregó / retiró el nuevo stock al cliente por el plazo extendido'
            };

            let work = { ...workDetail, extension: null };

            dispatch(startLoading())

            await axios.post(
                `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createWork}`, {
                collection: "Works",
                id: work.id.toString(),
                doc: { ...work, activity: sortActivity([...workDetail.activity, activityObj]) }
            }, { headers: { Authorization: `Bearer ${token}` } }
            )
                .then(() => {

                    dispatch(finishLoading())

                    setWorkDetail({ ...work, activity: sortActivity([...workDetail.activity, activityObj]) });

                    showMixinToast('Stock entregado / retirado', 'success');

                }).catch(async (e) => {

                    dispatch(finishLoading())

                    let msg = ''

                    if (e.response !== undefined) {

                        if (e.response.data.trace.includes('auth/id-token-expired')) {

                            msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                        } else {
                            msg = e.response.data.message
                        }

                    } else {
                        msg = 'Ha ocurrido un error.'
                    }

                    console.error({ msg, error: e });

                    await axios.post(
                        `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                        from: 'ERROR SR ANDAMIOS',
                        to: process.env.REACT_APP_SEND_EMAIL_TO,
                        name: 'Yo',
                        phone: '',
                        text: { name, msg, error: JSON.stringify(e, null, 2) }
                    })

                    dispatch(setError(msg));


                })

            dispatch(finishLoading())

            setVisible(false);

            //


        }



        const setTitleConfirm = () => {

            if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0
                && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) {
                return 'Estas a punto de indicar que se entregó y retiró el nuevo stock al cliente por plazo extendido'
            } else if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0
                && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length <= 0) {
                return 'Estas a punto de indicar que se entregó el nuevo stock al cliente por plazo extendido'
            } else if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length <= 0
                && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) {
                return 'Estas a punto de indicar que se retiró el nuevo stock al cliente por plazo extendido'
            } else {
                return ''
            }
        }

        showModalConfirm({
            title: '¿Estas seguro?',
            description: setTitleConfirm(),
            icon: 'warning',
            confirmButtonText: 'Confirmar',
            confirmButtonColor: colors.ok,
            cancelButtonColor: colors.danger,
            handleConfirm: () => { handleUpdateWorkList() }
        })

    }

    const setTitleExtensionButton = () => {

        if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0
            && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) {
            return 'Marcar como entregado / retirado el nuevo stock al cliente por plazo extendido'
        } else if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0
            && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length <= 0) {
            return 'Marcar como entregado el nuevo stock al cliente por plazo extendido'
        } else if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length <= 0
            && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) {
            return 'Marcar como retirado el nuevo stock al cliente por plazo extendido'
        } else {
            return ''
        }
    }

    const setDeliveryDateToday = () => {

        const handleUpdateWorkList = async () => {

            dispatch(startLoading());

            let work = workDetail;

            let activityObj: ActivityProps = {
                id: parseInt(getFullDate()),
                date: parseInt(getFullDate()),
                creator: name!,
                text: ''
            };

            work = { ...workDetail, retirementDate: { ...workDetail.retirementDate, date: parseInt(getDate()) } };
            activityObj = { ...activityObj, text: `Ha cambiado la fecha de retiro del ${formatDateDDmmYYYY(workDetail.retirementDate.date)} para el ${formatDateDDmmYYYY(parseInt(getDate()))}` };

            const newDoc = { ...work, activity: sortActivity([...workDetail.activity, activityObj]) };

            await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createWork}`,
                { collection: "Works", id: work.id.toString(), doc: newDoc },
                { headers: { Authorization: `Bearer ${token}` } })
                .then(() => {

                    dispatch(finishLoading())

                    setWorkDetail(newDoc);

                    showMixinToast('Retiro actualizado', 'success');

                }).catch(async (e) => {

                    dispatch(finishLoading())

                    let msg = ''

                    if (e.response !== undefined) {

                        if (e.response.data.trace.includes('auth/id-token-expired')) {

                            msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                        } else {
                            msg = e.response.data.message
                        }

                    } else {
                        msg = 'Ha ocurrido un error.'
                    }

                    console.error({ msg, error: e });

                    await axios.post(
                        `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                        from: 'ERROR SR ANDAMIOS',
                        to: process.env.REACT_APP_SEND_EMAIL_TO,
                        name: 'Yo',
                        phone: '',
                        text: { name, msg, error: JSON.stringify(e, null, 2) }
                    })

                    dispatch(setError(msg));

                })

            dispatch(finishLoading())

        }

        showModalConfirm({
            title: '¿Estas seguro?',
            description: 'Estas a punto de indicar que los productos estan listos para retirar ahora.',
            icon: 'warning',
            confirmButtonText: 'Confirmar',
            confirmButtonColor: colors.ok,
            cancelButtonColor: colors.danger,
            handleConfirm: () => { handleUpdateWorkList() }
        })

    }

    const workDetailActionButtonsData: ActionButtonsProps[] = [
        {
            id: 0,
            name: setTitleExtensionButton(),
            icon: require('../images/change-white.png'),
            type: 'primary',
            visible: true,
            styles: {
                backgroundColor: colors.warning_light,
                border: `1px solid ${colors.warning}`,
                color: colors.primary_dark,
                visibility: ((workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0)
                    || (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) ? 'visible' : 'hidden'),
                position: ((workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0)
                    || (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) ? 'relative' : 'absolute'),
            },
            handleClick: () => { setExtensionButtonAction() }
        },
        {
            id: 1,
            name: 'Listo para retirar ahora',
            icon: require('../images/change-white.png'),
            type: 'primary',
            visible: getIfDeliveryTodayExists(workDetail),
            handleClick: () => { setDeliveryDateToday() }
        },
        {
            id: 2,
            name: setButtonStateText(),
            icon: require('../images/change-white.png'),
            type: 'primary',
            finished: workDetail.state.name === 'Terminado',
            visible: true,
            handleClick: () => { setButtonStateAction() }
        },
        {
            id: 3,
            name: workDetail.state.name === 'Terminado' || workDetail.payment.filter((value) => { return !value.payed }).length === 0 ? 'Total cobrado' : 'Indicar cobro',
            icon: require('../images/payed-white.png'),
            type: 'primary',
            finished: workDetail.state.name === 'Terminado',
            visible: true,
            handleClick: () => { setSetPayedModalVisible(true) }
        },

        {
            id: 4,
            name: 'Extender plazo alquiler',
            icon: require('../images/more-days-primary-dark.png'),
            type: 'terciary',
            visible: true,
            handleClick: () => { setExtendDaysModalVisible(true) }
        },
        {
            id: 5,
            name: 'Editar tarea',
            icon: require('../images/edit.png'),
            type: 'terciary',
            visible: true,
            handleClick: () => { setEditWorkModalVisible(true) }
        },
        {
            id: 6,
            name: 'Eliminar tarea',
            icon: require('../images/delete-primary-dark.png'),
            type: 'terciary',
            visible: true,
            handleClick: () => {

                const handleDelete = async () => {

                    dispatch(startLoading())

                    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createWork}`,
                        { collection: "Works", id: workDetail.id.toString(), doc: { ...workDetail, fieldLocationPath: 'trash' as FieldLocationPathTypes } },
                        { headers: { Authorization: `Bearer ${token}` } })
                        .then(() => {

                            dispatch(finishLoading())

                            showMixinToast('Trabajo eliminado', 'success')

                        }).catch(async (e) => {

                            dispatch(finishLoading())

                            let msg = ''

                            if (e.response !== undefined) {

                                if (e.response.data.trace.includes('auth/id-token-expired')) {

                                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                                } else {
                                    msg = e.response.data.message
                                }

                            } else {
                                msg = 'Ha ocurrido un error.'
                            }

                            console.error({ msg, error: e });

                            await axios.post(
                                `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                                from: 'ERROR SR ANDAMIOS',
                                to: process.env.REACT_APP_SEND_EMAIL_TO,
                                name: 'Yo',
                                phone: '',
                                text: { name, msg, error: JSON.stringify(e, null, 2) }
                            })

                            dispatch(setError(msg));


                        })

                    setVisible(false);

                }

                showModalConfirm({
                    title: '¿Estas seguro?',
                    description: 'No vas a poder revertir esta acción.',
                    icon: 'warning',
                    confirmButtonText: 'Si, estoy seguro',
                    handleConfirm: () => { handleDelete() }
                })

            }
        },

    ]

    const handleSendComment = async () => {

        if (newCommentForm.text.trim() !== '') {

            dispatch(startLoading())

            await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createWork}`,
                { collection: "Works", id: workDetail.id.toString(), doc: { ...workDetail, comments: [...workDetail.comments, newCommentForm] } },
                { headers: { Authorization: `Bearer ${token}` } })
                .then(() => {

                    dispatch(finishLoading())

                    setWorkDetail({ ...workDetail, comments: [...workDetail.comments, newCommentForm] });

                    showMixinToast('Comentario agregado', 'success');

                    setNewCommentForm(initialStateNewComment);

                }).catch(async (e) => {

                    dispatch(finishLoading())

                    let msg = ''

                    if (e.response !== undefined) {

                        if (e.response.data.trace.includes('auth/id-token-expired')) {

                            msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                        } else {
                            msg = e.response.data.message
                        }

                    } else {
                        msg = 'Ha ocurrido un error.'
                    }

                    console.error({ msg, error: e });

                    await axios.post(
                        `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                        from: 'ERROR SR ANDAMIOS',
                        to: process.env.REACT_APP_SEND_EMAIL_TO,
                        name: 'Yo',
                        phone: '',
                        text: { name, msg, error: JSON.stringify(e, null, 2) }
                    })

                    dispatch(setError(msg));


                })

            dispatch(finishLoading())

            setNewCommentForm(initialStateNewComment);

        } else {

            if (newCommentForm.text.trim() === '') {

                setCommentFormIncomplete(true);
            }
        }

    }

    const setTitleExtensionBox = () => {

        if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0
            && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) {
            return 'Entrega / retiro plazo extendido del nuevo stock al cliente'
        } else if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0
            && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length <= 0) {
            return 'Entrega plazo extendido del nuevo stock al cliente'
        } else if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length <= 0
            && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) {
            return 'Retiro plazo extendido del nuevo stock al cliente'
        } else {
            return ''
        }
    }

    const [newContactModal, setNewContactModal] = useState<{ visible: boolean, data: ContactProps }>({
        visible: false,
        data: initialStateNewContact
    });

    const [replaceContactModal, setReplaceContactModal] = useState<{ visible: boolean, data: WorkProps }>({
        visible: false,
        data: initialStateNewWork
    });


    return (

        <ShadowContainer >

            <ModalCardContainer>

                {
                    editWorkModalVisible && (
                        <NewWorkModal
                            data={workDetail}
                            worksList={worksList}
                            setVisible={setEditWorkModalVisible}
                            setWorkDetail={setWorkDetail}
                        />
                    )
                }

                {
                    extendDaysModalVisible && (
                        <ExtendDaysModal
                            data={workDetail}
                            setVisible={setExtendDaysModalVisible}
                            setWorkDetail={setWorkDetail}
                        />
                    )
                }

                {
                    setPayedModalVisible && (
                        <SetPayedModal
                            data={workDetail}
                            setVisible={setSetPayedModalVisible}
                            setWorkDetail={setWorkDetail}
                        />
                    )
                }

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        {`${workDetail.client} `}
                    </Typography>

                    <Button
                        type="icon"
                        icon={require('../images/plus-primary-dark.png')}
                        onClick={() => {
                            setVisible(false);
                        }}
                        style={{ transform: 'rotate(45deg)' }}
                    />

                </HeaderContainer>

                <OptionWorkButtons
                    viewButtonsData={workDetailViewsButtonsData}
                    actionButtonsData={workDetailActionButtonsData.filter((value) => { return value.visible })}
                    viewSelected={'Información general'}
                    setViewSelected={() => { }}
                    onClickNewWork={() => { }}
                />

                <ContentContainer>

                    <DetailsContainer>

                        {
                            workDetail.extension !== null && (


                                <BoxContainer style={{ height: 'fit-content', borderRadius: 0, margin: 0, marginBottom: 20, backgroundColor: colors.warning_light, borderColor: colors.warning }}>

                                    <DetailTitleContainer>

                                        <Icon
                                            size="M"
                                            src={require('../images/proyects-primary.png')}
                                        />

                                        <Typography
                                            size='20px'
                                            bold
                                            style={{ marginLeft: 10, whiteSpace: 'normal', textAlign: 'left' }}
                                        >
                                            {setTitleExtensionBox()}
                                        </Typography>


                                    </DetailTitleContainer>

                                    <Input
                                        type="text"
                                        label="Fecha de entrega/retiro"
                                        value={`${formatDateText(workDetail.extension.date.date)} ${setDeliveryRetirementDateTextField(workDetail.extension.date)}`}
                                        readOnly
                                        onChange={() => { }}
                                    />

                                    {
                                        filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0 && (

                                            <SectionContainer fieldincomplete={''}>

                                                <SectionHeaderContainer fieldincomplete={''}>

                                                    <Typography size="20px">
                                                        Stock a entregar
                                                    </Typography>

                                                </SectionHeaderContainer>

                                                {

                                                    filterExtensionStock(workDetail.extension?.stock, 'Para entregar').map((value) => {

                                                        const getStockProductInWork = () => {

                                                            let productValue: StockProps = initialStateNewStock

                                                            if (!checkingWorks && !checkingStock) {

                                                                stockList.find((product: StockProps) => { if (value.id === product.id) productValue = product })

                                                            }

                                                            return productValue
                                                        }

                                                        return (

                                                            <SelectedStockItem
                                                                showAmounts={false}
                                                                showDelete={false}
                                                                disabled
                                                                extension
                                                                data={getStockProductInWork()}
                                                                form={workDetail}
                                                                setForm={setWorkDetail}
                                                                key={value.id}
                                                            />
                                                        )
                                                    })
                                                }

                                            </SectionContainer>

                                        )
                                    }

                                    {
                                        filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0 && (

                                            <SectionContainer fieldincomplete={''}>

                                                <SectionHeaderContainer fieldincomplete={''}>

                                                    <Typography size="20px">
                                                        Stock a retirar
                                                    </Typography>

                                                </SectionHeaderContainer>

                                                {

                                                    filterExtensionStock(workDetail.extension?.stock, 'Para retirar').map((value) => {

                                                        const getStockProductInWork = () => {

                                                            let productValue: StockProps = initialStateNewStock

                                                            if (!checkingWorks && !checkingStock) {

                                                                stockList.find((product: StockProps) => { if (value.id === product.id) productValue = product })

                                                            }

                                                            return productValue
                                                        }

                                                        return (

                                                            <SelectedStockItem
                                                                showAmounts={false}
                                                                showDelete={false}
                                                                disabled
                                                                extension
                                                                data={getStockProductInWork()}
                                                                form={workDetail}
                                                                setForm={setWorkDetail}
                                                                key={value.id}
                                                            />
                                                        )
                                                    })
                                                }

                                            </SectionContainer>

                                        )
                                    }

                                </BoxContainer>

                            )
                        }

                        <BoxContainer style={{ height: 'fit-content', borderRadius: 0, margin: 0, marginBottom: 20 }}>

                            <DetailTitleContainer>

                                <Icon
                                    size="M"
                                    src={require('../images/proyects-primary.png')}
                                />

                                <Typography
                                    size='20px'
                                    bold
                                    style={{ marginLeft: 10 }}
                                >
                                    Detalles
                                </Typography>


                            </DetailTitleContainer>

                            <Input
                                type="text"
                                label="Cliente"
                                value={workDetail.client}
                                readOnly
                                onChange={() => { }}
                            />

                            {/* <Input
                                type="text"
                                label="Cliente"
                                readOnly
                                onChange={() => { }}
                                children={

                                    <>


                                        {workDetail.clientId && getWorkContact(contacts, workDetail.clientId) ? (

                                            <>
                                                <ContactButton
                                                    type='contact'
                                                    onClick={() => {
                                                        setNewContactModal({ visible: true, data: getWorkContact(contacts, data.clientId ?? 0)! })
                                                    }}
                                                    text={getWorkContact(contacts, workDetail.clientId)?.nickname}
                                                />

                                                <ContactButton
                                                    type='replace'
                                                    onClick={() => {
                                                        setReplaceContactModal({
                                                            visible: true,
                                                            data: data
                                                        })
                                                    }}
                                                    text={data.client}
                                                />

                                            </>

                                        ) : (

                                            <>

                                                <Typography size='14px'>{workDetail.client}</Typography>

                                                <ContactButton
                                                    type='add'
                                                    onClick={() => {

                                                        showModalConfirm({
                                                            icon: 'question',
                                                            title: `${workDetail.client !== '' ? `"${workDetail.client.toUpperCase()}"` : 'Atención'}`,
                                                            description: '¿Qué acción desea realizar?',
                                                            confirmButtonText: 'Agregar como nuevo contacto',
                                                            cancelButtonText: 'Seleccionar contacto existente',
                                                            confirmButtonColor: colors.primary,
                                                            cancelButtonColor: colors.primary_dark,
                                                            handleConfirm: async () => {

                                                                try {

                                                                    dispatch(startLoading());

                                                                    const newId = parseInt(getFullDate());

                                                                    await startcreateUpdateContactQuery({ id: newId, nickname: workDetail.client }, token!, dispatch, name!)

                                                                    dispatch(startLoading());

                                                                    await startcreateUpdateWorkQuery({ ...workDetail, clientId: newId }, token!, dispatch, name!)

                                                                    await setWorkDetail({ ...workDetail, clientId: newId });

                                                                    await showMixinToast('Contacto creado', 'success');

                                                                } catch (e: any) {

                                                                    dispatch(finishLoading())

                                                                    let msg = ''

                                                                    if (e.response !== undefined) {

                                                                        if (e.response.data.trace?.includes('auth/id-token-expired')) {

                                                                            msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                                                                                "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                                                                        } else {
                                                                            msg = e.response.data.message
                                                                        }

                                                                    } else {
                                                                        msg = 'Ha ocurrido un error.'
                                                                    }

                                                                    console.error({ msg, error: e });

                                                                    await axios.post(
                                                                        `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                                                                        from: 'ERROR SR ANDAMIOS',
                                                                        to: process.env.REACT_APP_SEND_EMAIL_TO,
                                                                        name: 'Yo',
                                                                        phone: '',
                                                                        text: { name, msg, error: JSON.stringify(e, null, 2) }
                                                                    })

                                                                    dispatch(setError(msg));

                                                                }

                                                            },
                                                            handleConfirmSecondary: () => {

                                                                setReplaceContactModal({
                                                                    visible: true,
                                                                    data: data
                                                                })
                                                            },
                                                        })

                                                    }}
                                                    text={data.client}
                                                />

                                            </>

                                        )
                                        }
                                    </>

                                }
                            /> */}

                            <Input
                                type="text"
                                label="Dirección"
                                value={workDetail.address}
                                readOnly
                                onChange={() => { }}
                            />

                            <Input
                                type="select"
                                label="Estado"
                                state={`{"id": ${workDetail.state.id}, "name": "${workDetail.state.name}", "color": "${workDetail.state.color}"}`}
                                value={`{"id": ${workDetail.state.id}, "name": "${workDetail.state.name}", "color": "${workDetail.state.color}"}`}
                                readOnly

                                onChange={() => { }}
                            >
                                <option value={`{"id": 0, "name": "A confirmar", "color": "${colors.to_confirm}"}`}>A confirmar</option>
                                <option value={`{"id": 1, "name": "Para entregar", "color": "${colors.primary}"}`}>Para entregar</option>
                                <option value={`{"id": 2, "name": "Para retirar", "color": "${colors.warning}"}`}>Para retirar</option>
                                <option value={`{"id": 3, "name": "Falta cobrar", "color": "${colors.danger}"}`}>Falta cobrar</option>
                                <option value={`{"id": 4, "name": "Terminado", "color": "${colors.ok}"}`}>Terminado</option>
                            </Input>

                            <Input
                                type="text"
                                label="Fecha de entrega"
                                value={`${formatDateText(workDetail.deliveryDate.date)} ${setDeliveryRetirementDateTextField(workDetail.deliveryDate)}`}
                                readOnly
                                onChange={() => { }}
                            />

                            <Input
                                type="text"
                                label="Fecha de retiro"
                                value={`${formatDateText(workDetail.retirementDate.date)} ${setDeliveryRetirementDateTextField(workDetail.retirementDate)}`}
                                readOnly
                                onChange={() => { }}
                            />


                            <Input
                                type="text"
                                label="Dias de alquiler"
                                key="cantidadAlquiler"
                                value={workDetail.daysAmount}
                                readOnly
                                onChange={() => { }}
                            />

                            <SectionContainer fieldincomplete={''} style={{ paddingBottom: 30 }}>

                                <SectionHeaderContainer fieldincomplete={''} id="cobros">

                                    <Typography size="20px">
                                        Cobro
                                    </Typography>

                                </SectionHeaderContainer>

                                {
                                    sortPayment(workDetail.payment).map((value, index) => {

                                        return (

                                            <>

                                                <Typography size="20px" bold style={{ width: '100%', padding: 0, paddingLeft: 5, marginTop: 30 }}>
                                                    Metodo de cobro #{index + 1}
                                                </Typography>

                                                <NewPaymentItem
                                                    key={value.id}
                                                    dataItem={value}
                                                    form={workDetail}
                                                    setForm={setWorkDetail}
                                                    readOnly
                                                />

                                            </>
                                        )

                                    })
                                }

                            </SectionContainer>


                            <SectionContainer fieldincomplete={''}>

                                <SectionHeaderContainer fieldincomplete={''}>

                                    <Typography size="20px">
                                        Stock
                                    </Typography>

                                </SectionHeaderContainer>

                                {

                                    workDetail.stock.map((value) => {

                                        const getStockProductInWork = () => {

                                            let productValue: StockProps = initialStateNewStock

                                            if (!checkingWorks && !checkingStock) {

                                                stockList.find((product: StockProps) => { if (value.id === product.id) productValue = product })

                                            }

                                            return productValue
                                        }

                                        return (

                                            <SelectedStockItem
                                                showAmounts={false}
                                                showDelete={false}
                                                disabled
                                                data={getStockProductInWork()}
                                                form={workDetail}
                                                setForm={setWorkDetail}
                                                key={value.id}
                                            />
                                        )
                                    })
                                }

                            </SectionContainer>

                        </BoxContainer>

                    </DetailsContainer>

                    <SubcontentContainer>

                        <BoxContainer style={{ height: 'fit-content', borderRadius: 0, paddingLeft: 30, paddingRight: 30, margin: 0, zIndex: 0 }}>

                            <DetailTitleContainer>

                                <Icon
                                    size="M"
                                    src={require('../images/proyects-primary.png')}
                                />

                                <Typography
                                    size='20px'
                                    bold
                                    style={{ marginLeft: 10 }}
                                >
                                    Comentarios
                                </Typography>


                            </DetailTitleContainer>

                            {
                                sortActivity(workDetail.comments).map((value) => {

                                    return (

                                        <CommentItem key={value.id} data={value} workDetail={workDetail} setWorkDetail={setWorkDetail} />
                                    )

                                })
                            }

                            <InputCommentContainer>

                                <Input
                                    type="text"
                                    label=""
                                    value={newCommentForm.text}
                                    placeholder="Escribir acá"
                                    incomplete={commentFormIncomplete}
                                    onChange={(e) => {

                                        setCommentFormIncomplete(false);

                                        setNewCommentForm({
                                            id: parseInt(getFullDate()),
                                            creator: name!,
                                            date: parseInt(getFullDate()),
                                            text: e.target.value,
                                        })

                                    }}

                                    style={{
                                        height: 'auto',
                                        margin: 0,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        paddingRight: 20,
                                    }}
                                />

                                <Button
                                    type="action"
                                    icon={require('../images/send.png')}
                                    onClick={() => { handleSendComment() }}
                                    style={{ backgroundColor: 'transparent', borderColor: colors.border, height: 52, width: 52, marginLeft: 10, }}
                                />

                            </InputCommentContainer>



                        </BoxContainer>

                        <BoxContainer style={{ height: 'fit-content', borderRadius: 0, paddingBottom: 0, margin: 0, marginBottom: 20 }}>

                            <DetailTitleContainer>

                                <Icon
                                    size="M"
                                    src={require('../images/proyects-primary.png')}
                                />

                                <Typography
                                    size='20px'
                                    bold
                                    style={{ marginLeft: 10, marginBottom: workDetail.activity.length === 0 ? 15 : 0 }}
                                >
                                    Actividad
                                </Typography>


                            </DetailTitleContainer>

                            {
                                sortActivity(workDetail.activity).map((value) => {

                                    return (
                                        <ActivityItem key={value.id} data={value} />
                                    )

                                })
                            }

                        </BoxContainer>

                    </SubcontentContainer>

                </ContentContainer>

            </ModalCardContainer>

            {
                newContactModal.visible && (
                    <NewContactModal
                        data={newContactModal.data}
                        setVisible={setNewContactModal}
                        onFinish={(newId: number | undefined) => {
                            setWorkDetail({ ...workDetail, clientId: newId });
                        }}
                    />
                )
            }

            {
                replaceContactModal.visible && (
                    <SelectContactListModal
                        data={replaceContactModal.data}
                        setVisible={setReplaceContactModal}
                        onFinish={(newId: number | undefined) => {
                            setWorkDetail({ ...workDetail, clientId: newId });
                        }}
                    />
                )
            }

        </ShadowContainer >

    )
}


///////////////////


// import { css, styled } from "styled-components";
// import { colors, device } from "../styles/stylesConfig";
// import { Typography } from "./Typography";
// import { Button } from "./atoms/Button";
// import { OptionWorkButtons } from "./OptionWorkButtons";
// import { ActionButtonsProps, ActivityProps, CommentProps, FieldLocationPathTypes, ViewButtonsProps, WorkProps } from "../interfaces/WorkInterfaces";
// import { useState } from "react";
// import { BoxContainer } from "./BoxContainer";
// import { Icon } from "./Icon";
// import { formatDateText, getFullDate, sortActivity, sortPayment, setDeliveryRetirementDateTextField, filterExtensionStock, getWorkContact } from '../utils/Utilities';
// import { ActivityItem } from "./ActivityItem";
// import { CommentItem } from "./CommentItem";
// import { Input } from "./Input";
// import { useDispatch, useSelector } from "react-redux";
// import { initialStateNewComment, initialStateNewContact, initialStateNewStock, initialStateNewWork } from "../utils/Jsons";
// import { showMixinToast, showModalConfirm } from "../utils/Alerts";
// import { NewWorkModal } from "./NewWorkModal";
// import { ExtendDaysModal } from "./ExtendDaysModal";
// import { finishLoading, setError, startLoading } from "../actions/ui";
// import { SetPayedModal } from "./SetPayedModal";
// import { SelectedStockItem } from "./SelectedStockItem";
// import { NewPaymentItem } from "./NewPaymentItem";
// import axios from "axios";
// import { StockProps } from "../interfaces/StockInterfaces";
// import { endpoints } from "../helpers/FirebaseHelper";
// import { ContactButton } from "./ContactButton";
// import { StoreProps } from "../interfaces/ReducerInterfaces";
// import { startcreateUpdateContactQuery } from "../queries/ContactQueries";
// import { startcreateUpdateWorkQuery } from "../queries/WorkQueries";
// import { ContactProps } from "../interfaces/ContactInterfaces";
// import { NewContactModal } from "./NewContactModal";
// import { SelectContactListModal } from "./SelectContactListModal";

// const ShadowContainer = styled.div`
//     width: 100vw;
//     height: 100vh;
//     background-color: rgba(0,0,0,0.8);
//     position: fixed;
//     left: 0;
//     top: 0;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     z-index: 5;
//     backdrop-filter: blur(5px);
// `

// const ModalCardContainer = styled.div`
//     width: 90%;
//     max-width: 95%;
//     max-height: 90%;
//     box-shadow: 0px 3px 6px #00000026;
//     display: flex;
//     flex-direction: column;
//     border-radius: 10px;
//     padding: 50px;
//     padding-bottom: 0px;
//     background-color: white;
//     position: relative;
//     overflow-y: scroll;
//     white-space: nowrap;

//     @media (max-width: 1000px) {
//         width: 100%;
//         height: 100%;
//         max-width: 100%;
//         max-height: 100%;
//         border-radius: 0px;
//     }

//     @media ${device.sm} {
//         padding-left: 30px;
//         padding-right: 30px;
//     }

//     @media ${device.xs} {
//         padding-left: 20px;
//         padding-right: 20px;
//     }
// `

// const HeaderContainer = styled.div`
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: 50px;

//     & > h1 {
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//     }
// `

// const ContentContainer = styled.div`
//     width: 100%;
//     height: 100%;
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
//     column-gap: 10px;
//     row-gap: 10px;
//     margin-top: 50px;
//     margin-bottom: 50px;

//     @media ${device.md} {
//         grid-template-columns: repeat(1, 1fr);
//     }

//     & > div:first-child {
//         min-width: 400px;

//         @media ${device.md} {
//             min-width: auto;
//         }
//     }

//     @media ${device.sm} {
//         display: flex;
//         flex-direction: column;
//         grid-template-columns: none;
//     }
// `

// const DetailsContainer = styled.div`
//     width: 100%;
//     height: fit-content;
//     display: grid;
//     grid-template-columns: repeat(1, 1fr);
//     column-gap: 10px;
//     row-gap: 10px;
//     grid-column-start: 1;
//     grid-column-end: 2;

//     @media ${device.xl} {
//         grid-template-columns: repeat(1, 1fr);
//     }

//     @media ${device.md} {
//         grid-column-start: 1;
//         grid-column-end: 2;
//     }

//     & > div:nth-child(1)
//     & > div:nth-child(2) {
//         min-width: 400px;

//         @media ${device.md} {
//             min-width: auto;
//         }
//     }

//     @media ${device.sm} {
//         display: flex;
//         flex-direction: column;
//         grid-template-columns: none;
//         padding-bottom: 50px;
//     }

// `

// const SubcontentContainer = styled.div`
//     width: 100%;
//     height: fit-content;
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     column-gap: 10px;
//     row-gap: 10px;
//     grid-column-start: 2;
//     grid-column-end: 4;

//     @media ${device.xl} {
//         grid-template-columns: repeat(1, 1fr);
//     }

//     @media ${device.md} {
//         grid-column-start: 1;
//         grid-column-end: 2;
//     }

//     & > div:nth-child(1)
//     & > div:nth-child(2) {
//         min-width: 400px;

//         @media ${device.md} {
//             min-width: auto;
//         }
//     }

//     @media ${device.sm} {
//         display: flex;
//         flex-direction: column;
//         grid-template-columns: none;
//         padding-bottom: 50px;
//     }

// `

// const DetailTitleContainer = styled.div`
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     margin: 10px 0px;
//     padding: 0px 5px;
// `

// const InputCommentContainer = styled.div`
//     display: flex;
//     flex-direction: row;
//     align-items: start;
//     justify-content: center;
//     margin-top: 25px;
// `

// const SectionContainer = styled.div<{ fieldincomplete: string }>`
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin-top: 30px;
//     border-bottom: 1px solid ${colors.border};

//     & > h1 {
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         color: ${colors.grey_dark};
//         margin-top: 30px;
//         padding-bottom: 30px;
//     }

//     ${props => props.fieldincomplete === 'true' && css`
//         border-bottom: 1px solid ${colors.danger};
//     `}
// `

// const SectionHeaderContainer = styled.div<{ fieldincomplete: string }>`
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     border-bottom: 1px solid ${colors.border};
//     padding-bottom: 10px;

//     & > h1 {
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         width: 100%;
//         color: ${colors.grey_dark};
//     }

//     & > button {
//         color: ${colors.grey_dark};
//     }

//     ${props => props.fieldincomplete === 'true' && css`
//         border-bottom: 1px solid ${colors.danger};
//     `}
// `

// interface Props {
//     data: WorkProps;
//     worksList: WorkProps[];
//     setVisible: React.Dispatch<React.SetStateAction<boolean>>;
// }

// export const WorkDetailModal = ({ data, worksList, setVisible }: Props) => {

//     const { contacts } = useSelector((state: StoreProps) => state.srandamios);

//     const { name, token } = useSelector((state: StoreProps) => state.auth);

//     const { stock: stockList, checkingStock, checkingWorks } = useSelector((state: any) => state.srandamios);

//     const dispatch = useDispatch();

//     const [newCommentForm, setNewCommentForm] = useState<CommentProps>(initialStateNewComment);

//     const [commentFormIncomplete, setCommentFormIncomplete] = useState<boolean>(false);

//     const [editWorkModalVisible, setEditWorkModalVisible] = useState<boolean>(false);

//     const [extendDaysModalVisible, setExtendDaysModalVisible] = useState<boolean>(false);

//     const [setPayedModalVisible, setSetPayedModalVisible] = useState<boolean>(false);

//     const [workDetail, setWorkDetail] = useState<WorkProps>(data);

//     const workDetailViewsButtonsData: ViewButtonsProps[] = [
//         {
//             id: 1,
//             name: 'Información general',
//             icon: require('../images/gral-info-primary-dark.png'),
//             iconSelected: require('../images/gral-info-primary.png'),
//         },
//     ]

//     const setButtonStateText = () => {

//         switch (workDetail.state.name) {
//             case 'A confirmar':
//                 return 'Confirmar trabajo'
//             case 'Para entregar':
//                 return 'Marcar como entregado'
//             case 'Para retirar':
//                 return 'Marcar como retirado'
//             case 'Falta cobrar':
//                 return 'Marcar como cobrado'
//             case 'Terminado':
//                 return 'Terminado'
//         }

//     }

//     const setButtonStateAction = () => {

//         const handleUpdateWorkList = async () => {

//             dispatch(startLoading());

//             let work = workDetail;

//             let activityObj: ActivityProps = {
//                 id: parseInt(getFullDate()),
//                 date: parseInt(getFullDate()),
//                 creator: name!,
//                 text: 'ha realizado un cambio de estado'
//             };

//             switch (workDetail.state.name) {
//                 case 'A confirmar':
//                     work = { ...workDetail, state: { id: 1, name: 'Para entregar', color: colors.primary } };
//                     activityObj = { ...activityObj, changeState: { stateFrom: { id: 0, name: 'A confirmar', color: colors.to_confirm }, status: 'changed', stateTo: { id: 1, name: 'Para entregar', color: colors.primary } } };
//                     break;
//                 case 'Para entregar':
//                     work = { ...workDetail, state: { id: 2, name: 'Para retirar', color: colors.warning } };
//                     activityObj = { ...activityObj, changeState: { stateFrom: { id: 1, name: 'Para entregar', color: colors.primary }, status: 'changed', stateTo: { id: 2, name: 'Para retirar', color: colors.warning } } };
//                     break;
//                 case 'Para retirar':

//                     if (workDetail.payment.filter((value) => { return !value.payed }).length === 0) {

//                         work = { ...workDetail, state: { id: 4, name: 'Terminado', color: colors.ok }, payment: [...workDetail.payment.map((value) => { return { ...value, payed: true } })] };
//                         activityObj = { ...activityObj, changeState: { stateFrom: { id: 3, name: 'Falta cobrar', color: colors.danger }, status: 'changed', stateTo: { id: 4, name: 'Terminado', color: colors.ok } } };
//                         break;
//                     } else {

//                         work = { ...workDetail, state: { id: 3, name: 'Falta cobrar', color: colors.danger } };
//                         activityObj = { ...activityObj, changeState: { stateFrom: { id: 2, name: 'Para retirar', color: colors.warning }, status: 'changed', stateTo: { id: 3, name: 'Falta cobrar', color: colors.danger } } };
//                         break;

//                     }

//                 case 'Falta cobrar':
//                     work = { ...workDetail, state: { id: 4, name: 'Terminado', color: colors.ok }, payment: [...workDetail.payment.map((value) => { return { ...value, payed: true } })] };
//                     activityObj = { ...activityObj, changeState: { stateFrom: { id: 3, name: 'Falta cobrar', color: colors.danger }, status: 'changed', stateTo: { id: 4, name: 'Terminado', color: colors.ok } } };
//                     break;
//                 case 'Terminado':
//                     break;
//             }

//             await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createWork}`,
//                 { collection: "Works", id: work.id.toString(), doc: { ...work, activity: sortActivity([...workDetail.activity, activityObj]) } },
//                 { headers: { Authorization: `Bearer ${token}` } })
//                 .then(() => {

//                     dispatch(finishLoading())

//                     setWorkDetail({ ...work, activity: sortActivity([...workDetail.activity, activityObj]) });

//                     showMixinToast('Estado actualizado', 'success');

//                 }).catch(async (e) => {

//                     dispatch(finishLoading())

//                     let msg = ''

//                     if (e.response !== undefined) {

//                         if (e.response.data.trace.includes('auth/id-token-expired')) {

//                             msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
//                                 "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

//                         } else {
//                             msg = e.response.data.message
//                         }

//                     } else {
//                         msg = 'Ha ocurrido un error.'
//                     }

//                     console.error({ msg, error: e });

//                     await axios.post(
//                         `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
//                         from: 'ERROR SR ANDAMIOS',
//                         to: process.env.REACT_APP_SEND_EMAIL_TO,
//                         name: 'Yo',
//                         phone: '',
//                         text: { name, msg, error: JSON.stringify(e, null, 2) }
//                     })

//                     dispatch(setError(msg));

//                 })

//             dispatch(finishLoading())

//         }

//         const setTitleConfirm = () => {

//             switch (workDetail.state.name) {
//                 case 'A confirmar':
//                     return 'Estas a punto de confirmar el trabajo. Esto implica que se descontarán los productos del stock y la tarea pasará al estado PARA ENTREGAR.'
//                 case 'Para entregar':
//                     return 'Estas a punto de indicar que se entregaron los productos. La tarea pasará al estado PARA RETIRAR.'
//                 case 'Para retirar':

//                     if (workDetail.payment.filter((value) => { return !value.payed }).length === 0)
//                         return 'Estas a punto de indicar que se retiraron los productos. Esto implica que se agregaran nuevamente al stock disponible. Debido a que se cobró el monto total registrado, la tarea pasará al estado TERMINADO.'
//                     else
//                         return 'Estas a punto de indicar que se retiraron los productos. Esto implica que se agregaran nuevamente al stock disponible. La tarea pasará al estado FALTA COBRAR.'

//                 case 'Falta cobrar':
//                     return 'Estas a punto de indicar que se cobró el valor total de la tarea. La tarea pasará al estado TERMINADO.'
//                 default:
//                     return ''
//             }

//         }

//         showModalConfirm({
//             title: '¿Estas seguro?',
//             description: setTitleConfirm(),
//             icon: 'warning',
//             confirmButtonText: 'Confirmar',
//             confirmButtonColor: colors.ok,
//             cancelButtonColor: colors.danger,
//             handleConfirm: () => { handleUpdateWorkList() }
//         })

//     }

//     const setExtensionButtonAction = () => {

//         const handleUpdateWorkList = async () => {

//             dispatch(startLoading());

//             let activityObj: ActivityProps = {
//                 id: parseInt(getFullDate()),
//                 date: parseInt(getFullDate()),
//                 creator: name!,
//                 text: 'ha indicado que se entregó / retiró el nuevo stock al cliente por el plazo extendido'
//             };

//             let work = { ...workDetail, extension: null };

//             dispatch(startLoading())

//             await axios.post(
//                 `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createWork}`, {
//                 collection: "Works",
//                 id: work.id.toString(),
//                 doc: { ...work, activity: sortActivity([...workDetail.activity, activityObj]) }
//             }, { headers: { Authorization: `Bearer ${token}` } }
//             )
//                 .then(() => {

//                     dispatch(finishLoading())

//                     setWorkDetail({ ...work, activity: sortActivity([...workDetail.activity, activityObj]) });

//                     showMixinToast('Stock entregado / retirado', 'success');

//                 }).catch(async (e) => {

//                     dispatch(finishLoading())

//                     let msg = ''

//                     if (e.response !== undefined) {

//                         if (e.response.data.trace.includes('auth/id-token-expired')) {

//                             msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
//                                 "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

//                         } else {
//                             msg = e.response.data.message
//                         }

//                     } else {
//                         msg = 'Ha ocurrido un error.'
//                     }

//                     console.error({ msg, error: e });

//                     await axios.post(
//                         `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
//                         from: 'ERROR SR ANDAMIOS',
//                         to: process.env.REACT_APP_SEND_EMAIL_TO,
//                         name: 'Yo',
//                         phone: '',
//                         text: { name, msg, error: JSON.stringify(e, null, 2) }
//                     })

//                     dispatch(setError(msg));


//                 })

//             dispatch(finishLoading())

//             setVisible(false);

//             //


//         }



//         const setTitleConfirm = () => {

//             if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0
//                 && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) {
//                 return 'Estas a punto de indicar que se entregó y retiró el nuevo stock al cliente por plazo extendido'
//             } else if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0
//                 && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length <= 0) {
//                 return 'Estas a punto de indicar que se entregó el nuevo stock al cliente por plazo extendido'
//             } else if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length <= 0
//                 && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) {
//                 return 'Estas a punto de indicar que se retiró el nuevo stock al cliente por plazo extendido'
//             } else {
//                 return ''
//             }
//         }

//         showModalConfirm({
//             title: '¿Estas seguro?',
//             description: setTitleConfirm(),
//             icon: 'warning',
//             confirmButtonText: 'Confirmar',
//             confirmButtonColor: colors.ok,
//             cancelButtonColor: colors.danger,
//             handleConfirm: () => { handleUpdateWorkList() }
//         })

//     }

//     const setTitleExtensionButton = () => {

//         if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0
//             && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) {
//             return 'Marcar como entregado / retirado el nuevo stock al cliente por plazo extendido'
//         } else if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0
//             && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length <= 0) {
//             return 'Marcar como entregado el nuevo stock al cliente por plazo extendido'
//         } else if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length <= 0
//             && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) {
//             return 'Marcar como retirado el nuevo stock al cliente por plazo extendido'
//         } else {
//             return ''
//         }
//     }

//     const workDetailActionButtonsData: ActionButtonsProps[] = [
//         {
//             id: 0,
//             name: setTitleExtensionButton(),
//             icon: require('../images/change-white.png'),
//             type: 'primary',
//             styles: {
//                 backgroundColor: colors.warning_light,
//                 border: `1px solid ${colors.warning}`,
//                 color: colors.primary_dark,
//                 visibility: ((workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0)
//                     || (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) ? 'visible' : 'hidden'),
//                 position: ((workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0)
//                     || (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) ? 'relative' : 'absolute'),
//             },
//             handleClick: () => { setExtensionButtonAction() }
//         },
//         {
//             id: 1,
//             name: setButtonStateText(),
//             icon: require('../images/change-white.png'),
//             type: 'primary',
//             finished: workDetail.state.name === 'Terminado',
//             handleClick: () => { setButtonStateAction() }
//         },
//         {
//             id: 2,
//             name: workDetail.state.name === 'Terminado' || workDetail.payment.filter((value) => { return !value.payed }).length === 0 ? 'Total cobrado' : 'Indicar cobro',
//             icon: require('../images/payed-white.png'),
//             type: 'primary',
//             finished: workDetail.state.name === 'Terminado',
//             handleClick: () => { setSetPayedModalVisible(true) }
//         },
//         {
//             id: 3,
//             name: 'Extender plazo alquiler',
//             icon: require('../images/more-days-primary-dark.png'),
//             type: 'terciary',
//             handleClick: () => { setExtendDaysModalVisible(true) }
//         },
//         {
//             id: 4,
//             name: 'Editar tarea',
//             icon: require('../images/edit.png'),
//             type: 'terciary',
//             handleClick: () => { setEditWorkModalVisible(true) }
//         },
//         {
//             id: 5,
//             name: 'Eliminar tarea',
//             icon: require('../images/delete-primary-dark.png'),
//             type: 'terciary',
//             handleClick: () => {

//                 const handleDelete = async () => {

//                     dispatch(startLoading())

//                     await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createWork}`,
//                         { collection: "Works", id: workDetail.id.toString(), doc: { ...workDetail, fieldLocationPath: 'trash' as FieldLocationPathTypes } },
//                         { headers: { Authorization: `Bearer ${token}` } })
//                         .then(() => {

//                             dispatch(finishLoading())

//                             showMixinToast('Trabajo eliminado', 'success')

//                         }).catch(async (e) => {

//                             dispatch(finishLoading())

//                             let msg = ''

//                             if (e.response !== undefined) {

//                                 if (e.response.data.trace.includes('auth/id-token-expired')) {

//                                     msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
//                                         "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

//                                 } else {
//                                     msg = e.response.data.message
//                                 }

//                             } else {
//                                 msg = 'Ha ocurrido un error.'
//                             }

//                             console.error({ msg, error: e });

//                             await axios.post(
//                                 `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
//                                 from: 'ERROR SR ANDAMIOS',
//                                 to: process.env.REACT_APP_SEND_EMAIL_TO,
//                                 name: 'Yo',
//                                 phone: '',
//                                 text: { name, msg, error: JSON.stringify(e, null, 2) }
//                             })

//                             dispatch(setError(msg));


//                         })

//                     setVisible(false);

//                 }

//                 showModalConfirm({
//                     title: '¿Estas seguro?',
//                     description: 'No vas a poder revertir esta acción.',
//                     icon: 'warning',
//                     confirmButtonText: 'Si, estoy seguro',
//                     handleConfirm: () => { handleDelete() }
//                 })

//             }
//         },

//     ]

//     const handleSendComment = async () => {

//         if (newCommentForm.text.trim() !== '') {

//             dispatch(startLoading())

//             await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createWork}`,
//                 { collection: "Works", id: workDetail.id.toString(), doc: { ...workDetail, comments: [...workDetail.comments, newCommentForm] } },
//                 { headers: { Authorization: `Bearer ${token}` } })
//                 .then(() => {

//                     dispatch(finishLoading())

//                     setWorkDetail({ ...workDetail, comments: [...workDetail.comments, newCommentForm] });

//                     showMixinToast('Comentario agregado', 'success');

//                     setNewCommentForm(initialStateNewComment);

//                 }).catch(async (e) => {

//                     dispatch(finishLoading())

//                     let msg = ''

//                     if (e.response !== undefined) {

//                         if (e.response.data.trace.includes('auth/id-token-expired')) {

//                             msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
//                                 "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

//                         } else {
//                             msg = e.response.data.message
//                         }

//                     } else {
//                         msg = 'Ha ocurrido un error.'
//                     }

//                     console.error({ msg, error: e });

//                     await axios.post(
//                         `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
//                         from: 'ERROR SR ANDAMIOS',
//                         to: process.env.REACT_APP_SEND_EMAIL_TO,
//                         name: 'Yo',
//                         phone: '',
//                         text: { name, msg, error: JSON.stringify(e, null, 2) }
//                     })

//                     dispatch(setError(msg));


//                 })

//             dispatch(finishLoading())

//             setNewCommentForm(initialStateNewComment);

//         } else {

//             if (newCommentForm.text.trim() === '') {

//                 setCommentFormIncomplete(true);
//             }
//         }

//     }

//     const setTitleExtensionBox = () => {

//         if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0
//             && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) {
//             return 'Entrega / retiro plazo extendido del nuevo stock al cliente'
//         } else if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0
//             && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length <= 0) {
//             return 'Entrega plazo extendido del nuevo stock al cliente'
//         } else if (workDetail.extension?.stock && filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length <= 0
//             && filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0) {
//             return 'Retiro plazo extendido del nuevo stock al cliente'
//         } else {
//             return ''
//         }
//     }

//     const [newContactModal, setNewContactModal] = useState<{ visible: boolean, data: ContactProps }>({
//         visible: false,
//         data: initialStateNewContact
//     });

//     const [replaceContactModal, setReplaceContactModal] = useState<{ visible: boolean, data: WorkProps }>({
//         visible: false,
//         data: initialStateNewWork
//     });


//     return (

//         <ShadowContainer >

//             <ModalCardContainer>

//                 {
//                     editWorkModalVisible && (
//                         <NewWorkModal
//                             data={workDetail}
//                             worksList={worksList}
//                             setVisible={setEditWorkModalVisible}
//                             setWorkDetail={setWorkDetail}
//                         />
//                     )
//                 }

//                 {
//                     extendDaysModalVisible && (
//                         <ExtendDaysModal
//                             data={workDetail}
//                             setVisible={setExtendDaysModalVisible}
//                             setWorkDetail={setWorkDetail}
//                         />
//                     )
//                 }

//                 {
//                     setPayedModalVisible && (
//                         <SetPayedModal
//                             data={workDetail}
//                             setVisible={setSetPayedModalVisible}
//                             setWorkDetail={setWorkDetail}
//                         />
//                     )
//                 }

//                 <HeaderContainer>

//                     <Typography
//                         size='28px'
//                         bold
//                     >
//                         {`${workDetail.client} `}
//                     </Typography>

//                     <Button
//                         type="icon"
//                         icon={require('../images/plus-primary-dark.png')}
//                         onClick={() => {
//                             setVisible(false);
//                         }}
//                         style={{ transform: 'rotate(45deg)' }}
//                     />

//                 </HeaderContainer>

//                 <OptionWorkButtons
//                     viewButtonsData={workDetailViewsButtonsData}
//                     actionButtonsData={workDetailActionButtonsData}
//                     viewSelected={'Información general'}
//                     setViewSelected={() => { }}
//                     onClickNewWork={() => { }}
//                 />

//                 <ContentContainer>

//                     <DetailsContainer>

//                         {
//                             workDetail.extension !== null && (


//                                 <BoxContainer style={{ height: 'fit-content', borderRadius: 0, margin: 0, marginBottom: 20, backgroundColor: colors.warning_light, borderColor: colors.warning }}>

//                                     <DetailTitleContainer>

//                                         <Icon
//                                             size="M"
//                                             src={require('../images/proyects-primary.png')}
//                                         />

//                                         <Typography
//                                             size='20px'
//                                             bold
//                                             style={{ marginLeft: 10, whiteSpace: 'normal', textAlign: 'left' }}
//                                         >
//                                             {setTitleExtensionBox()}
//                                         </Typography>


//                                     </DetailTitleContainer>

//                                     <Input
//                                         type="text"
//                                         label="Fecha de entrega/retiro"
//                                         value={`${formatDateText(workDetail.extension.date.date)} ${setDeliveryRetirementDateTextField(workDetail.extension.date)}`}
//                                         readOnly
//                                         onChange={() => { }}
//                                     />

//                                     {
//                                         filterExtensionStock(workDetail.extension?.stock, 'Para entregar').length > 0 && (

//                                             <SectionContainer fieldincomplete={''}>

//                                                 <SectionHeaderContainer fieldincomplete={''}>

//                                                     <Typography size="20px">
//                                                         Stock a entregar
//                                                     </Typography>

//                                                 </SectionHeaderContainer>

//                                                 {

//                                                     filterExtensionStock(workDetail.extension?.stock, 'Para entregar').map((value) => {

//                                                         const getStockProductInWork = () => {

//                                                             let productValue: StockProps = initialStateNewStock

//                                                             if (!checkingWorks && !checkingStock) {

//                                                                 stockList.find((product: StockProps) => { if (value.id === product.id) productValue = product })

//                                                             }

//                                                             return productValue
//                                                         }

//                                                         return (

//                                                             <SelectedStockItem
//                                                                 showAmounts={false}
//                                                                 showDelete={false}
//                                                                 disabled
//                                                                 extension
//                                                                 data={getStockProductInWork()}
//                                                                 form={workDetail}
//                                                                 setForm={setWorkDetail}
//                                                                 key={value.id}
//                                                             />
//                                                         )
//                                                     })
//                                                 }

//                                             </SectionContainer>

//                                         )
//                                     }

//                                     {
//                                         filterExtensionStock(workDetail.extension?.stock, 'Para retirar').length > 0 && (

//                                             <SectionContainer fieldincomplete={''}>

//                                                 <SectionHeaderContainer fieldincomplete={''}>

//                                                     <Typography size="20px">
//                                                         Stock a retirar
//                                                     </Typography>

//                                                 </SectionHeaderContainer>

//                                                 {

//                                                     filterExtensionStock(workDetail.extension?.stock, 'Para retirar').map((value) => {

//                                                         const getStockProductInWork = () => {

//                                                             let productValue: StockProps = initialStateNewStock

//                                                             if (!checkingWorks && !checkingStock) {

//                                                                 stockList.find((product: StockProps) => { if (value.id === product.id) productValue = product })

//                                                             }

//                                                             return productValue
//                                                         }

//                                                         return (

//                                                             <SelectedStockItem
//                                                                 showAmounts={false}
//                                                                 showDelete={false}
//                                                                 disabled
//                                                                 extension
//                                                                 data={getStockProductInWork()}
//                                                                 form={workDetail}
//                                                                 setForm={setWorkDetail}
//                                                                 key={value.id}
//                                                             />
//                                                         )
//                                                     })
//                                                 }

//                                             </SectionContainer>

//                                         )
//                                     }

//                                 </BoxContainer>

//                             )
//                         }

//                         <BoxContainer style={{ height: 'fit-content', borderRadius: 0, margin: 0, marginBottom: 20 }}>

//                             <DetailTitleContainer>

//                                 <Icon
//                                     size="M"
//                                     src={require('../images/proyects-primary.png')}
//                                 />

//                                 <Typography
//                                     size='20px'
//                                     bold
//                                     style={{ marginLeft: 10 }}
//                                 >
//                                     Detalles
//                                 </Typography>


//                             </DetailTitleContainer>

//                             <Input
//                                 type="text"
//                                 label="Cliente"
//                                 value={workDetail.client}
//                                 readOnly
//                                 onChange={() => { }}
//                                 children={

//                                     <>


//                                         {workDetail.clientId && getWorkContact(contacts, workDetail.clientId) ? (

//                                             <>
//                                                 <ContactButton
//                                                     type='contact'
//                                                     onClick={() => {
//                                                         setNewContactModal({ visible: true, data: getWorkContact(contacts, data.clientId ?? 0)! })
//                                                     }}
//                                                     text={getWorkContact(contacts, workDetail.clientId)?.nickname}
//                                                 />

//                                                 <ContactButton
//                                                     type='replace'
//                                                     onClick={() => {
//                                                         setReplaceContactModal({
//                                                             visible: true,
//                                                             data: data
//                                                         })
//                                                     }}
//                                                     text={data.client}
//                                                 />

//                                             </>

//                                         ) : (

//                                             <>

//                                                 <Typography size='14px'>{workDetail.client}</Typography>

//                                                 <ContactButton
//                                                     type='add'
//                                                     onClick={() => {

//                                                         showModalConfirm({
//                                                             icon: 'question',
//                                                             title: `${workDetail.client !== '' ? `"${workDetail.client.toUpperCase()}"` : 'Atención'}`,
//                                                             description: '¿Qué acción desea realizar?',
//                                                             confirmButtonText: 'Agregar como nuevo contacto',
//                                                             cancelButtonText: 'Seleccionar contacto existente',
//                                                             confirmButtonColor: colors.primary,
//                                                             cancelButtonColor: colors.primary_dark,
//                                                             handleConfirm: async () => {

//                                                                 try {

//                                                                     dispatch(startLoading());

//                                                                     const newId = parseInt(getFullDate());

//                                                                     await startcreateUpdateContactQuery({ id: newId, nickname: workDetail.client }, token!, dispatch, name!)

//                                                                     dispatch(startLoading());

//                                                                     await startcreateUpdateWorkQuery({ ...workDetail, clientId: newId }, token!, dispatch, name!)

//                                                                     await setWorkDetail({ ...workDetail, clientId: newId });

//                                                                     await showMixinToast('Contacto creado', 'success');

//                                                                 } catch (e: any) {

//                                                                     dispatch(finishLoading())

//                                                                     let msg = ''

//                                                                     if (e.response !== undefined) {

//                                                                         if (e.response.data.trace?.includes('auth/id-token-expired')) {

//                                                                             msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
//                                                                                 "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

//                                                                         } else {
//                                                                             msg = e.response.data.message
//                                                                         }

//                                                                     } else {
//                                                                         msg = 'Ha ocurrido un error.'
//                                                                     }

//                                                                     console.error({ msg, error: e });

//                                                                     await axios.post(
//                                                                         `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
//                                                                         from: 'ERROR SR ANDAMIOS',
//                                                                         to: process.env.REACT_APP_SEND_EMAIL_TO,
//                                                                         name: 'Yo',
//                                                                         phone: '',
//                                                                         text: { name, msg, error: JSON.stringify(e, null, 2) }
//                                                                     })

//                                                                     dispatch(setError(msg));

//                                                                 }

//                                                             },
//                                                             handleConfirmSecondary: () => {

//                                                                 setReplaceContactModal({
//                                                                     visible: true,
//                                                                     data: data
//                                                                 })
//                                                             },
//                                                         })

//                                                     }}
//                                                     text={data.client}
//                                                 />

//                                             </>

//                                         )
//                                         }
//                                     </>

//                                 }
//                             />
//                             {/* <Typography size='14px' color={address === '' ? colors.grey : colors.primary_dark}>{address === '' ? '( Vacío )' : address}</Typography> */}

//                             <Input
//                                 type="text"
//                                 label="Dirección"
//                                 value={workDetail.address === '' ? '( Vacío )' : workDetail.address}
//                                 style={{ color: workDetail.address === '' ? colors.grey : colors.primary_dark }}
//                                 readOnly
//                                 onChange={() => { }}
//                             />

//                             <Input
//                                 type="select"
//                                 label="Estado"
//                                 state={`{"id": ${workDetail.state.id}, "name": "${workDetail.state.name}", "color": "${workDetail.state.color}"}`}
//                                 value={`{"id": ${workDetail.state.id}, "name": "${workDetail.state.name}", "color": "${workDetail.state.color}"}`}
//                                 readOnly

//                                 onChange={() => { }}
//                             >
//                                 <option value={`{"id": 0, "name": "A confirmar", "color": "${colors.to_confirm}"}`}>A confirmar</option>
//                                 <option value={`{"id": 1, "name": "Para entregar", "color": "${colors.primary}"}`}>Para entregar</option>
//                                 <option value={`{"id": 2, "name": "Para retirar", "color": "${colors.warning}"}`}>Para retirar</option>
//                                 <option value={`{"id": 3, "name": "Falta cobrar", "color": "${colors.danger}"}`}>Falta cobrar</option>
//                                 <option value={`{"id": 4, "name": "Terminado", "color": "${colors.ok}"}`}>Terminado</option>
//                             </Input>

//                             <Input
//                                 type="text"
//                                 label="Fecha de entrega"
//                                 value={`${formatDateText(workDetail.deliveryDate.date)} ${setDeliveryRetirementDateTextField(workDetail.deliveryDate)}`}
//                                 readOnly
//                                 onChange={() => { }}
//                             />

//                             <Input
//                                 type="text"
//                                 label="Fecha de retiro"
//                                 value={`${formatDateText(workDetail.retirementDate.date)} ${setDeliveryRetirementDateTextField(workDetail.retirementDate)}`}
//                                 readOnly
//                                 onChange={() => { }}
//                             />


//                             <Input
//                                 type="text"
//                                 label="Dias de alquiler"
//                                 key="cantidadAlquiler"
//                                 value={workDetail.daysAmount}
//                                 readOnly
//                                 onChange={() => { }}
//                             />

//                             <SectionContainer fieldincomplete={''} style={{ paddingBottom: 30 }}>

//                                 <SectionHeaderContainer fieldincomplete={''} id="cobros">

//                                     <Typography size="20px">
//                                         Cobro
//                                     </Typography>

//                                 </SectionHeaderContainer>

//                                 {
//                                     sortPayment(workDetail.payment).map((value, index) => {

//                                         return (

//                                             <>

//                                                 <Typography size="20px" bold style={{ width: '100%', padding: 0, paddingLeft: 5, marginTop: 30 }}>
//                                                     Metodo de cobro #{index + 1}
//                                                 </Typography>

//                                                 <NewPaymentItem
//                                                     key={value.id}
//                                                     dataItem={value}
//                                                     form={workDetail}
//                                                     setForm={setWorkDetail}
//                                                     readOnly
//                                                 />

//                                             </>
//                                         )

//                                     })
//                                 }

//                             </SectionContainer>


//                             <SectionContainer fieldincomplete={''}>

//                                 <SectionHeaderContainer fieldincomplete={''}>

//                                     <Typography size="20px">
//                                         Stock
//                                     </Typography>

//                                 </SectionHeaderContainer>

//                                 {

//                                     workDetail.stock.map((value) => {

//                                         const getStockProductInWork = () => {

//                                             let productValue: StockProps = initialStateNewStock

//                                             if (!checkingWorks && !checkingStock) {

//                                                 stockList.find((product: StockProps) => { if (value.id === product.id) productValue = product })

//                                             }

//                                             return productValue
//                                         }

//                                         return (

//                                             <SelectedStockItem
//                                                 showAmounts={false}
//                                                 showDelete={false}
//                                                 disabled
//                                                 data={getStockProductInWork()}
//                                                 form={workDetail}
//                                                 setForm={setWorkDetail}
//                                                 key={value.id}
//                                             />
//                                         )
//                                     })
//                                 }

//                             </SectionContainer>

//                         </BoxContainer>

//                     </DetailsContainer>

//                     <SubcontentContainer>

//                         <BoxContainer style={{ height: 'fit-content', borderRadius: 0, paddingLeft: 30, paddingRight: 30, margin: 0, zIndex: 0 }}>

//                             <DetailTitleContainer>

//                                 <Icon
//                                     size="M"
//                                     src={require('../images/proyects-primary.png')}
//                                 />

//                                 <Typography
//                                     size='20px'
//                                     bold
//                                     style={{ marginLeft: 10 }}
//                                 >
//                                     Comentarios
//                                 </Typography>


//                             </DetailTitleContainer>

//                             {
//                                 sortActivity(workDetail.comments).map((value) => {

//                                     return (

//                                         <CommentItem key={value.id} data={value} workDetail={workDetail} setWorkDetail={setWorkDetail} />
//                                     )

//                                 })
//                             }

//                             <InputCommentContainer>

//                                 <Input
//                                     type="text"
//                                     label=""
//                                     value={newCommentForm.text}
//                                     placeholder="Escribir acá"
//                                     incomplete={commentFormIncomplete}
//                                     onChange={(e) => {

//                                         setCommentFormIncomplete(false);

//                                         setNewCommentForm({
//                                             id: parseInt(getFullDate()),
//                                             creator: name!,
//                                             date: parseInt(getFullDate()),
//                                             text: e.target.value,
//                                         })

//                                     }}

//                                     style={{
//                                         height: 'auto',
//                                         margin: 0,
//                                         whiteSpace: 'nowrap',
//                                         overflow: 'hidden',
//                                         textOverflow: 'ellipsis',
//                                         paddingRight: 20,
//                                     }}
//                                 />

//                                 <Button
//                                     type="action"
//                                     icon={require('../images/send.png')}
//                                     onClick={() => { handleSendComment() }}
//                                     style={{ backgroundColor: 'transparent', borderColor: colors.border, height: 52, width: 52, marginLeft: 10, }}
//                                 />

//                             </InputCommentContainer>



//                         </BoxContainer>

//                         <BoxContainer style={{ height: 'fit-content', borderRadius: 0, paddingBottom: 0, margin: 0, marginBottom: 20 }}>

//                             <DetailTitleContainer>

//                                 <Icon
//                                     size="M"
//                                     src={require('../images/proyects-primary.png')}
//                                 />

//                                 <Typography
//                                     size='20px'
//                                     bold
//                                     style={{ marginLeft: 10, marginBottom: workDetail.activity.length === 0 ? 15 : 0 }}
//                                 >
//                                     Actividad
//                                 </Typography>


//                             </DetailTitleContainer>

//                             {
//                                 sortActivity(workDetail.activity).map((value) => {

//                                     return (
//                                         <ActivityItem key={value.id} data={value} />
//                                     )

//                                 })
//                             }

//                         </BoxContainer>

//                     </SubcontentContainer>

//                 </ContentContainer>

//             </ModalCardContainer>

//             {
//                 newContactModal.visible && (
//                     <NewContactModal
//                         data={newContactModal.data}
//                         setVisible={setNewContactModal}
//                         onFinish={(newId: number | undefined) => {
//                             setWorkDetail({ ...workDetail, clientId: newId });
//                         }}
//                     />
//                 )
//             }

//             {
//                 replaceContactModal.visible && (
//                     <SelectContactListModal
//                         data={replaceContactModal.data}
//                         setVisible={setReplaceContactModal}
//                         onFinish={(newId: number | undefined) => {
//                             setWorkDetail({ ...workDetail, clientId: newId });
//                         }}
//                     />
//                 )
//             }

//         </ShadowContainer >

//     )
// }
