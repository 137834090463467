import { styled } from 'styled-components';
import { Typography } from '../../../components/Typography';
import { colors, device } from '../../../styles/stylesConfig';
import { useEffect, useState } from 'react';
import { Calendar } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { addHours } from 'date-fns';
import { getMessagesES, localizer } from '../../../helpers/CalendarHelper';
import { CalendarEventBox } from '../../../components/CalendarEventBox';
import { CalendarEventProps } from '../../../interfaces/CalendarInterfaces';
import { PaymentProps, WorkProps } from '../../../interfaces/WorkInterfaces';
import { initialStateNewWork } from '../../../utils/Jsons';
import { formatFullDateTimeTZ, getWorksListSorted, setBgColorCalendarEvent } from '../../../utils/Utilities';
import { WorkDetailModal } from '../../../components/WorkDetailModal';
import { LoadingComponent } from '../../../components/LoadingComponent';
import { useSelector } from 'react-redux';

const ScreenContainer = styled.div`
position: relative;
width: 100%;

    @media ${device.lg} {

        & > h1 {
        visibility: hidden;
        position: absolute;
        }
    }

    @media ${device.sm} {
        max-width: 100%;
    }
`

export const CalendarScreen = () => {

    const { works: worksList, checkingWorks } = useSelector((state: any) => state.srandamios);

    const [lastView, setLastView] = useState(localStorage.getItem('lastViewCalendar') ?? 'week');

    const [workEventSelected, setWorkEventSelected] = useState<WorkProps>(initialStateNewWork);

    const [showCalendarDetailModal, setShowCalendarDetailModal] = useState(false);

    const getDeliveryRetirementEventListFormatted = () => {

        let list: CalendarEventProps[] = []

        getWorksListSorted(worksList.filter((value: WorkProps) => { return value.state.name !== 'A confirmar' }), 'active').forEach((value: WorkProps) => {

            list = [
                ...list,
                {
                    title: 'ENTREGA : ' + value.client,
                    notes: JSON.stringify(value),
                    start: new Date(formatFullDateTimeTZ(value.deliveryDate.date, value.deliveryDate.hourStart)),
                    end: new Date(formatFullDateTimeTZ(value.deliveryDate.date, value.deliveryDate.hourEnd)),
                    bgColor: setBgColorCalendarEvent('delivery', value.state.name),
                    user: {
                        _id: value.id,
                        name: value.creator
                    }
                },
                {
                    title: 'RETIRO : ' + value.client,
                    notes: JSON.stringify(value),
                    start: new Date(formatFullDateTimeTZ(value.retirementDate.date, value.retirementDate.hourStart)),
                    end: new Date(formatFullDateTimeTZ(value.retirementDate.date, value.retirementDate.hourEnd)),
                    bgColor: setBgColorCalendarEvent('retirement', value.state.name),
                    user: {
                        _id: value.id,
                        name: value.creator
                    }
                }
            ]

        })

        return list

    }

    const getPaymentEventListFormatted = () => {

        let list: CalendarEventProps[] = []

        getWorksListSorted(worksList.filter((value: WorkProps) => { return value.state.name !== 'A confirmar' }), 'active').forEach((value: WorkProps) => {

            value.payment.forEach((payment: PaymentProps) => {

                let datePayment
                let hourStartPayment
                let hourEndPayment

                if (payment.paymentInstance === 'Al momento de la entrega') {
                    datePayment = value.deliveryDate.date
                    hourStartPayment = value.deliveryDate.hourStart
                    hourEndPayment = value.deliveryDate.hourEnd
                } else if (payment.paymentInstance === 'Al momento del retiro') {
                    datePayment = value.retirementDate.date
                    hourStartPayment = value.retirementDate.hourStart
                    hourEndPayment = value.retirementDate.hourEnd
                } else {
                    datePayment = payment.dateTime.date
                    hourStartPayment = payment.dateTime.hourStart
                    hourEndPayment = payment.dateTime.hourEnd
                }

                list = [
                    ...list,
                    {
                        title: 'COBRO : ' + value.client,
                        notes: JSON.stringify(value),
                        start: new Date(formatFullDateTimeTZ(datePayment, hourStartPayment)),
                        end: new Date(formatFullDateTimeTZ(datePayment, hourEndPayment)),
                        bgColor: setBgColorCalendarEvent('payment', value.state.name),
                        user: {
                            _id: payment.id,
                            name: value.creator
                        }
                    },
                ]
            })

        })

        return list

    }

    const getExtendEventListFormatted = () => {

        let list: CalendarEventProps[] = []

        getWorksListSorted(worksList.filter((value: WorkProps) => { return value.state.name !== 'A confirmar' }), 'active').forEach((value: WorkProps) => {

            if (value.extension) {

                list = [
                    ...list,
                    {
                        title: 'EXTENSION : ' + value.client,
                        notes: JSON.stringify(value),
                        start: new Date(formatFullDateTimeTZ(value.extension.date.date, value.extension.date.hourStart)),
                        end: new Date(formatFullDateTimeTZ(value.extension.date.date, value.extension.date.hourEnd)),
                        bgColor: setBgColorCalendarEvent('extension', value.state.name),
                        user: {
                            _id: value.extension.date.id,
                            name: value.creator
                        }
                    }
                ]

            }

        })

        return list

    }

    const eventStyleGetter = (event: CalendarEventProps) => {

        let backgroundColor = event.bgColor;

        const work: WorkProps = JSON.parse(event.notes);

        if (event.title.includes('ENTREGA : ') && (work.state.name !== 'A confirmar' && work.state.name !== 'Para entregar')) {
            backgroundColor = colors.ok
        }
        if (event.title.includes('RETIRO : ') && (work.state.name !== 'A confirmar' && work.state.name !== 'Para entregar' && work.state.name !== 'Para retirar')) {
            backgroundColor = colors.ok
        }
        if (event.title.includes('COBRO : ')) {

            const value = work.payment.find((value) => { return value.id === event.user._id && value.payed })

            if (value) {
                backgroundColor = colors.ok
            }

        }

        const style = {
            backgroundColor,
            color: 'white'
        }

        return {
            style
        }

    }

    const onSelect = (event: CalendarEventProps) => {

        setWorkEventSelected(JSON.parse(event.notes));
        setShowCalendarDetailModal(true);

    }

    const onViewChanged = (event: string) => {

        setLastView(event);
        localStorage.setItem('lastViewCalendar', event);
    }

    return (

        <ScreenContainer>

            <Typography
                size='24px'
                bold
                style={{ marginBottom: 30 }}
            >
                Calendario
            </Typography>

            {
                checkingWorks ? (
                    <LoadingComponent />
                ) :
                    (
                        <Calendar
                            culture='es'
                            defaultView={lastView as any}
                            defaultDate={new Date()}
                            localizer={localizer}
                            events={[
                                ...getDeliveryRetirementEventListFormatted(),
                                ...getPaymentEventListFormatted(),
                                ...getExtendEventListFormatted(),
                            ]}
                            messages={getMessagesES()}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ minHeight: 500, height: '70vh' }}
                            components={{ event: CalendarEventBox }}
                            eventPropGetter={eventStyleGetter}
                            onSelectEvent={onSelect}
                            onView={onViewChanged}
                            scrollToTime={addHours(new Date(), -1)}
                        />
                    )
            }

            {
                showCalendarDetailModal && (
                    <WorkDetailModal
                        data={workEventSelected}
                        worksList={worksList}
                        setVisible={setShowCalendarDetailModal}
                    />
                )
            }

        </ScreenContainer>


    )
}
