import { types } from '../types/types';

export const saveSrAndamiosWorks = (worksList: any) => (
    {
        type: types.srAndamiosSetWorks,
        payload: {
            works: worksList
        }
    }
)

export const removeSrAndamiosWorks = () => ({
    type: types.srAndamiosRemoveWorks,
})

export const setCheckingSrAndamiosWorks = () => ({
    type: types.setCheckingSrAndamiosWorks,
})

export const removeCheckingSrAndamiosWorks = () => ({
    type: types.removeCheckingSrAndamiosWorks,
})

export const saveSrAndamiosStock = (stockList: any) => (
    {
        type: types.srAndamiosSetStock,
        payload: {
            stock: stockList
        }
    }
)

export const removeSrAndamiosStock = () => ({
    type: types.srAndamiosRemoveStock,
})

export const setCheckingSrAndamiosStock = () => ({
    type: types.setCheckingSrAndamiosStock,
})

export const removeCheckingSrAndamiosStock = () => ({
    type: types.removeCheckingSrAndamiosStock,
})

export const saveSrAndamiosMovements = (movementsList: any) => (
    {
        type: types.srAndamiosSetMovements,
        payload: {
            movements: movementsList
        }
    }
)

export const removeSrAndamiosMovements = () => ({
    type: types.srAndamiosRemoveMovements,
})

export const setCheckingSrAndamiosMovements = () => ({
    type: types.setCheckingSrAndamiosMovements,
})

export const removeCheckingSrAndamiosMovements = () => ({
    type: types.removeCheckingSrAndamiosMovements,
})

export const setCheckingSrAndamiosContacts = () => ({
    type: types.setCheckingSrAndamiosContacts,
})

export const removeCheckingSrAndamiosContacts = () => ({
    type: types.removeCheckingSrAndamiosContacts,
})


export const saveSrAndamiosContacts = (contactsList: any) => (
    {
        type: types.srAndamiosSetContacts,
        payload: {
            contacts: contactsList
        }
    }
)

export const removeSrAndamiosContacts = () => ({
    type: types.srAndamiosRemoveContacts,
})
