import { SecondaryScreensContainer } from '../../components/SecondaryScreensContainer';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { WorksScreen } from './inProyect/WorksScreen';
import { StockScreen } from './inProyect/StockScreen';
import { BudgetScreen } from './inProyect/BudgetScreen';
import { CalendarScreen } from './inProyect/CalendarScreen';
import { ContactsScreen } from './inProyect/ContactsScreen';

export const ProyectScreen = () => {

    const location = useLocation();

    const [pathname, setPathname] = useState('');

    useEffect(() => {

        if (location.pathname.includes('/works')) {
            setPathname('/works');
        } else if (location.pathname.includes('/calendar')) {
            setPathname('/calendar');
        } else if (location.pathname.includes('/stock')) {
            setPathname('/stock');
        } else if (location.pathname.includes('/budget')) {
            setPathname('/budget');
        } else if (location.pathname.includes('/contacts')) {
            setPathname('/contacts');
        } else {
            setPathname('');
        }

    }, [location])

    const getPage = (pathname: string) => {

        switch (pathname) {
            case '/works':
                return <WorksScreen />
            case '/calendar':
                return <CalendarScreen />
            case '/stock':
                return <StockScreen />
            case '/budget':
                return <BudgetScreen />
            case '/contacts':
                return <ContactsScreen />
        }
    }

    return (

        <SecondaryScreensContainer>

            {getPage(pathname)}

        </SecondaryScreensContainer>

    )
}
