import { styled } from "styled-components";
import { Icon } from "./Icon";
import { CSSProperties } from "react";
import { Typography } from "./Typography";

interface Props {
    icon?: string
    text?: string
    checked: boolean
    disabled?: boolean
    fullWidth?: boolean
    style?: CSSProperties
    onClick: () => void
}

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;

    & > h1 {
        white-space: normal;
        text-align: start;
        margin-left: 10px;
        width: 100%;
    }
    
    & > img {
        margin-left: 15px;
    }
`

const CheckboxInput = styled.input`
    white-space: 'normal';
    font-size: 18px;
    cursor: pointer;
`

export const Checkbox = ({ text, icon, checked, disabled, fullWidth, style, onClick }: Props) => {


    return (

        <CheckboxContainer style={{ ...style }}>

            <CheckboxInput
                type="checkbox"
                id={text}
                name={text}
                disabled={disabled}
                checked={checked}
                style={{
                    width: fullWidth ? '100%' : 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onChange={() => onClick()}
            >

            </CheckboxInput>

            {icon ? (
                <Icon
                    size="M"
                    src={icon}
                />
            ) : null
            }

            {text ? (
                <Typography
                    size="14px"
                >
                    {text}
                </Typography>
            ) : null
            }
        </CheckboxContainer>

    )
}
