import { differenceInCalendarDays } from "date-fns";
import { BudgetMovementProps } from "../interfaces/BudgetInterfaces";
import { StockProps, WorkStockProps, ToDeliverRetireTypes } from "../interfaces/StockInterfaces";
import { ActivityProps, FieldLocationPathTypes, HourFieldTypes, StateWorkProps, WorkProps, TimeTypes, PaymentProps, SetDateTimeProps, StateNameTypes } from '../interfaces/WorkInterfaces';
import { colors } from "../styles/stylesConfig";
import { hoursJson } from "./Jsons";
import { ContactProps } from "../interfaces/ContactInterfaces";

export const getFullDate = () => {

    const date = new Date();

    let fullDate = '';
    const year = date.getFullYear();
    const month = formatTwoNumbers(date.getMonth() + 1);
    const day = formatTwoNumbers(date.getDate());
    const hours = formatTwoNumbers(date.getHours());
    const minutes = formatTwoNumbers(date.getMinutes());
    const seconds = formatTwoNumbers(date.getSeconds());
    const miliseconds = fillWithCeros(date.getMilliseconds().toString(), 3);

    fullDate = `${year}${month}${day}${hours}${minutes}${seconds}${miliseconds}`;

    return fullDate;

}

export const getFullDateAsTZ = () => {

    const date = new Date();

    let fullDate = '';
    const year = date.getFullYear();
    const month = formatTwoNumbers(date.getMonth() + 1);
    const day = formatTwoNumbers(date.getDate());
    const hours = formatTwoNumbers(date.getHours());
    const minutes = formatTwoNumbers(date.getMinutes());
    const seconds = formatTwoNumbers(date.getSeconds());
    const miliseconds = fillWithCeros(date.getMilliseconds().toString(), 3);

    fullDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${miliseconds}Z`;

    return fullDate;

}

export const getDate = () => {

    const date = new Date();

    let fullDate = '';
    const year = date.getFullYear();
    const month = formatTwoNumbers(date.getMonth() + 1);
    const day = date.getDate();

    fullDate = `${year}${month}${day}`;

    return fullDate;

}


export const formatFullDateTimeAsTZ = (date: number) => {

    let ml = date.toString().slice(14, 17);
    let s = date.toString().slice(12, 14);
    let mn = date.toString().slice(10, 12);
    let h = date.toString().slice(8, 10);
    let d = date.toString().slice(6, 8);
    let m = date.toString().slice(4, 6);
    let y = date.toString().slice(0, 4);

    return `${y}-${m}-${d}T${h}:${mn}:${s}.${ml}Z`;

}


const formatTwoNumbers = (month: any) => {

    let m = month;

    if (m < 10) {

        m = `0${m}`;

    }

    return m;

}

export const formatPrice = (price: any) => {

    let p = price;
    const internationarFormat = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 })

    p = internationarFormat.format(p);

    return p;

}

export const formatDateDDmmYYYY = (date: number) => {

    let d = date.toString().slice(6, 8);
    let m = date.toString().slice(4, 6);
    let y = date.toString().slice(0, 4);

    return `${d}/${m}/${y}`;

}

export const formatDateYYYYmmDD = (date: number) => {

    let d = date.toString().slice(6, 8);
    let m = date.toString().slice(4, 6);
    let y = date.toString().slice(0, 4);

    return `${y}-${m}-${d}`;

}

export const formatTimeTZ = (time: string) => {

    let m = time.slice(2, 5);
    let h = time.slice(0, 2);

    h = (parseInt(h) + 3).toString();

    if (parseInt(h) < 10) h = '0' + h

    return `T${h}${m}:00.000Z`;

}

export const formatFullDateTimeTZ = (date: number, time: string) => {

    return formatDateYYYYmmDD(date) + formatTimeTZ(time);

}

export const formatDateYYYYmmDDhhMMssMM = (date: number) => {

    let ml = date.toString().slice(12, 14);
    let s = date.toString().slice(10, 12);
    let h = date.toString().slice(8, 10);
    let d = date.toString().slice(6, 8);
    let m = date.toString().slice(4, 6);
    let y = date.toString().slice(0, 4);

    return `${y}-${m}-${d}-${h}-${s}-${ml}`;

}

export const formatDateText = (date: number) => {

    const newDate = new Date(formatDateYYYYmmDD(date));

    let dayOfWeek = '';
    let month = '';

    let d = date.toString().slice(6, 8);
    let m = date.toString().slice(4, 6);

    switch (newDate.getDay()) {
        case 0:
            dayOfWeek = 'Lun';
            break;
        case 1:
            dayOfWeek = 'Mar';
            break;
        case 2:
            dayOfWeek = 'Mie';
            break;
        case 3:
            dayOfWeek = 'Jue';
            break;
        case 4:
            dayOfWeek = 'Vie';
            break;
        case 5:
            dayOfWeek = 'Sab';
            break;
        case 6:
            dayOfWeek = 'Dom';
            break;
    }

    switch (m) {
        case '01':
            month = 'ene.';
            break;
        case '02':
            month = 'feb.';
            break;
        case '03':
            month = 'mar.';
            break;
        case '04':
            month = 'abr.';
            break;
        case '05':
            month = 'may.';
            break;
        case '06':
            month = 'jun.';
            break;
        case '07':
            month = 'jul.';
            break;
        case '08':
            month = 'ago.';
            break;
        case '09':
            month = 'sep.';
            break;
        case '10':
            month = 'oct.';
            break;
        case '11':
            month = 'nov.';
            break;
        case '12':
            month = 'dic.';
            break;

    }

    return `${dayOfWeek} ${d} ${month}`;

}

export const formatDateHourText = (date: number) => {

    const newDate = new Date(formatDateYYYYmmDD(date));

    let dayOfWeek = '';

    let min = date.toString().slice(10, 12);
    let h = date.toString().slice(8, 10);
    let d = date.toString().slice(6, 8);
    let m = date.toString().slice(4, 6);
    let y = date.toString().slice(0, 4);


    switch (newDate.getDay()) {
        case 0:
            dayOfWeek = 'Lun';
            break;
        case 1:
            dayOfWeek = 'Mar';
            break;
        case 2:
            dayOfWeek = 'Mie';
            break;
        case 3:
            dayOfWeek = 'Jue';
            break;
        case 4:
            dayOfWeek = 'Vie';
            break;
        case 5:
            dayOfWeek = 'Sab';
            break;
        case 6:
            dayOfWeek = 'Dom';
            break;
    }

    return `${dayOfWeek} ${d}/${m}/${y}, ${h}:${min}hs`;

}

export const formatFullDateText = (date: number) => {

    const newDate = new Date(formatDateYYYYmmDD(date));

    let dayOfWeek = '';
    let month = '';

    let min = date.toString().slice(10, 12);
    let h = date.toString().slice(8, 10);
    let d = date.toString().slice(6, 8);
    let m = date.toString().slice(4, 6);
    let y = date.toString().slice(0, 4);


    switch (newDate.getDay()) {
        case 0:
            dayOfWeek = 'Lun';
            break;
        case 1:
            dayOfWeek = 'Mar';
            break;
        case 2:
            dayOfWeek = 'Mie';
            break;
        case 3:
            dayOfWeek = 'Jue';
            break;
        case 4:
            dayOfWeek = 'Vie';
            break;
        case 5:
            dayOfWeek = 'Sab';
            break;
        case 6:
            dayOfWeek = 'Dom';
            break;
    }

    switch (m) {
        case '01':
            month = 'enero';
            break;
        case '02':
            month = 'febrero';
            break;
        case '03':
            month = 'marzo';
            break;
        case '04':
            month = 'abril';
            break;
        case '05':
            month = 'mayo';
            break;
        case '06':
            month = 'junio';
            break;
        case '07':
            month = 'juio';
            break;
        case '08':
            month = 'agosto';
            break;
        case '09':
            month = 'septiembre';
            break;
        case '10':
            month = 'octubre';
            break;
        case '11':
            month = 'noviembre';
            break;
        case '12':
            month = 'diciembre';
            break;

    }

    return `${dayOfWeek} ${d} ${month} de ${y}, ${h}:${min}hs`;

}

export const sortStock = (stockList: StockProps[] | WorkStockProps[]) => {

    return stockList.sort((a, b) => { return a.id - b.id })
}

export const sortBudget = (budgetList: BudgetMovementProps[]) => {

    return budgetList.sort((a, b) => {

        if (a.date < b.date) {
            return 1
        } else if (a.date === b.date) {
            return 0
        } else {
            return -1
        }

    })
}

export const sortActivity = (activityList: ActivityProps[]) => {

    return activityList.sort((a, b) => {

        if (a.date < b.date) {
            return 1
        } else if (a.date === b.date) {
            return 0
        } else {
            return -1
        }

    })
}

export const sortPayment = (paymentList: PaymentProps[]) => {

    return paymentList.sort((a, b) => {

        if (a.id < b.id) {
            return -1
        } else if (a.id === b.id) {
            return 0
        } else {
            return 1
        }

    })
}

export const getWorksListSorted = (worksList: WorkProps[], filterBy?: FieldLocationPathTypes) => {

    let list = worksList.sort((a, b) => {

        if ((a.state.name === 'Para entregar' && a.deliveryDate < b.deliveryDate)
            || (a.state.name !== 'Para entregar' && b.state.name !== 'Para entregar' && a.retirementDate > b.retirementDate)
            || (a.state.name !== 'Para entregar' && b.state.name === 'Para entregar' && a.deliveryDate < b.retirementDate)
        ) {
            return 1
        } else if (a.state.name === b.state.name) {
            return 0
        } else {
            return -1
        }

    })

    if (filterBy)
        list = list.filter((value) => { return value.fieldLocationPath === filterBy });

    return list
}

export const getWorkContact = (contactsList: ContactProps[], clientId: number) => {

    const contact = contactsList.find((value) => { return value.id === clientId });

    return contact
}

export const getWorksListFilteredByState = (worksList: WorkProps[], { name }: StateWorkProps) => {

    let list = worksList.sort((a, b) => {

        if ((a.state.name === 'Para entregar' && a.deliveryDate < b.deliveryDate)
            || (a.state.name !== 'Para entregar' && b.state.name !== 'Para entregar' && a.retirementDate > b.retirementDate)
            || (a.state.name !== 'Para entregar' && b.state.name === 'Para entregar' && a.deliveryDate < b.retirementDate)
        ) {
            return 1
        } else if (a.state.name === b.state.name) {
            return 0
        } else {
            return -1
        }

    })

    list = list.filter((value) => { return value.state.name === name });

    return list
}

export const getWorksListSortedByDaysLeft = (worksList: WorkProps[], filterBy?: FieldLocationPathTypes) => {

    let list = [...worksList]

    const calcularDiasRestantes = (item: WorkProps) => {
        const fechaHoy = new Date();
        if (item.state.name === 'Para entregar') {
            return differenceInCalendarDays(new Date(formatFullDateTimeAsTZ(parseInt(item.deliveryDate.date.toString().padEnd(17, '0')))), fechaHoy);
        } else if (item.state.name === 'Para retirar') {
            return differenceInCalendarDays(new Date(formatFullDateTimeAsTZ(parseInt(item.retirementDate.date.toString().padEnd(17, '0')))), fechaHoy);
        }
        return 0; // Manejo de casos donde el estado no está definido
    };

    if (filterBy)
        list = list.filter((value) => { return value.fieldLocationPath === filterBy });

    // Ordenar la lista por días restantes
    const listaOrdenada = [...list].sort(
        (a, b) => calcularDiasRestantes(a) - calcularDiasRestantes(b)
    );

    return listaOrdenada
}


export const getIfDeliveryTodayExists = (item: WorkProps) => {

    const exists = (daysLeft: number) => {

        if (daysLeft > 0) {

            return true

        } else {

            return false
        }

    }

    const fechaHoy = new Date();
    if (item.state.name === 'Para retirar') {
        const daysLeft = differenceInCalendarDays(new Date(formatFullDateTimeAsTZ(parseInt(item.retirementDate.date.toString().padEnd(17, '0')))), fechaHoy) + 1;

        return exists(daysLeft)
    }

    return false;
};

export const getStateTitleDaysLeft = (item: WorkProps) => {

    const textOptions = (daysLeft: number) => {

        if (daysLeft > 0) {

            if (daysLeft === 1) {
                return 'mañana'
            } else {
                return 'dentro de ' + daysLeft + ' días';
            }

        } else if (daysLeft === 0) {

            return 'hoy'

        } else {

            if (daysLeft === -1) {
                return 'ayer'
            } else {
                return 'hace ' + -daysLeft + ' días';
            }

        }

    }

    const fechaHoy = new Date();
    if (item.state.name === 'Para entregar') {

        const daysLeft = differenceInCalendarDays(new Date(formatFullDateTimeAsTZ(parseInt(item.deliveryDate.date.toString().padEnd(17, '0')))), fechaHoy) + 1;

        return `Para entregar ${textOptions(daysLeft)}`


    } else if (item.state.name === 'Para retirar') {
        const daysLeft = differenceInCalendarDays(new Date(formatFullDateTimeAsTZ(parseInt(item.retirementDate.date.toString().padEnd(17, '0')))), fechaHoy) + 1;

        return `Para retirar ${textOptions(daysLeft)}`
    }

    return 0; // Manejo de casos donde el estado no está definido
};

export const getWorksListNotFilteredByState = (worksList: WorkProps[], { name }: StateWorkProps) => {

    let list = worksList.sort((a, b) => {

        if ((a.state.name === 'Para entregar' && a.deliveryDate < b.deliveryDate)
            || (a.state.name !== 'Para entregar' && b.state.name !== 'Para entregar' && a.retirementDate > b.retirementDate)
            || (a.state.name !== 'Para entregar' && b.state.name === 'Para entregar' && a.deliveryDate < b.retirementDate)
        ) {
            return 1
        } else if (a.state.name === b.state.name) {
            return 0
        } else {
            return -1
        }

    })

    list = list.filter((value) => { return value.state.name !== name });

    return list
}

export const setLabelHourText = (hourType: HourFieldTypes) => {

    switch (hourType) {
        case 'Horario específico':
            return 'A las...';
        case 'Después de las...':
            return 'Después de las...';
        case 'Intervalo de horas':
            return 'Desde las...';
        default:
            return '';
    }
}

export const getNextHalfPastHour = (hour: TimeTypes) => {

    const number = hoursJson.findIndex((value) => { return value === hour })

    if (number === 47) {
        return hoursJson[0] as TimeTypes
    } else {
        return hoursJson[number + 1] as TimeTypes
    }
}


export const setDeliveryRetirementDateTextField = (date: SetDateTimeProps) => {

    switch (date.hourType) {
        case 'Horario específico':
            return `A las ${date.hourStart}`
        case 'Antes de las...':
            return `A las ${date.hourEnd}`
        case 'Después de las...':
            return `Después de las ${date.hourStart}`
        case 'Intervalo de horas':
            return `Desde las ${date.hourStart} hasta las ${date.hourEnd}`
    }
}

export const filterExtensionStock = (stockList: WorkStockProps[], toDeliverRetire: ToDeliverRetireTypes) => {

    let list = stockList.sort((a, b) => { return a.id - b.id })

    list = list.filter((value) => { return value.toDeliverRetire === toDeliverRetire });

    return list;
}

export const setBgColorCalendarEvent = (eventType: 'delivery' | 'retirement' | 'payment' | 'extension', status: StateNameTypes) => {

    switch (eventType) {
        case 'delivery':
            return colors.primary
        case 'retirement':
            return colors.warning
        case 'payment':
            return colors.danger
        case 'extension':
            return colors.extended_event
    }
}

export const fillWithCeros = (inputString: string, length: number) => {
    // Verificar la longitud actual del string
    if (inputString.length < length) {
        // Calcular la cantidad de ceros que se deben agregar
        const cerosLeft = length - inputString.length;

        // Completar con ceros a la izquierda
        const string = '0'.repeat(cerosLeft) + inputString;

        return string;
    } else {
        // Si ya tiene 12 caracteres o más, devolver el string original
        return inputString;
    }
}


export const formatExpirationTime = (expirationTime: string) => {

    const a = expirationTime
    const fecha = new Date(a);

    // Obtener los componentes de fecha
    const año = fecha.getFullYear();
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Los meses en JavaScript van de 0 a 11
    const dia = fecha.getDate().toString().padStart(2, '0');
    const hora = fecha.getHours().toString().padStart(2, '0'); // Utilizar directamente la hora sin ajustes
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
    const segundos = fecha.getSeconds().toString().padStart(2, '0');
    const milisegundos = fecha.getMilliseconds().toString().padEnd(3, '0');

    // Formatear la salida
    const formato = año + mes + dia + hora + minutos + segundos + milisegundos;

    return parseInt(formato) - 3000000
}

export const getWorksListSortedByFinished = (worksList: WorkProps[], filterBy?: FieldLocationPathTypes) => {

    let list = worksList.sort((a, b) => {

        const finishedDateA = a.activity.find((value) => { return value.changeState?.stateTo.name === 'Terminado' })?.date ?? 0;
        const finishedDateB = b.activity.find((value) => { return value.changeState?.stateTo.name === 'Terminado' })?.date ?? 0;

        if (finishedDateA < finishedDateB) {
            return 1
        } else if (finishedDateA === finishedDateB) {
            return 0
        } else {
            return -1
        }

    })

    if (filterBy)
        list = list.filter((value) => { return value.fieldLocationPath === filterBy });

    return list
}