import { styled } from "styled-components";
import { device } from "../styles/stylesConfig";
import { Typography } from "./Typography";
import { Button } from "./atoms/Button";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { useEffect, useState } from "react";
import { Input } from "./Input";
import { getFullDate } from "../utils/Utilities";
import { showMixinToast, showModalMessage } from "../utils/Alerts";
import { initialStateNewContact } from "../utils/Jsons";
import { useDispatch, useSelector } from "react-redux";
import { finishLoading, startLoading } from "../actions/ui";
import { ContactProps } from "../interfaces/ContactInterfaces";
import { startcreateUpdateContactQuery } from "../queries/ContactQueries";
import { isIOS, isMobile } from 'react-device-detect';

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 7;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 50%;
    max-width: 95%;
    max-height: 80%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 50px;
    position: relative;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;

    @media ${device.lg} {
        width: 80%;
    } 


    @media ${device.md} {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0px;
    } 

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${device.sm} {
            font-size: 24px;
        }
    }
`

const RowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > div {
        margin-left: 10px;
        margin-right: 10px;

        &:first-of-type {
            margin-left: 0px;
        }

        &:last-of-type {
            margin-right: 0px;
        }

        @media ${device.md} {
            margin-left: 0px;
            margin-right: 0px;
        } 
        
    }

    @media ${device.md} {
        flex-direction: column;
    } 
`


interface Props {
    data: ContactProps;
    setVisible: React.Dispatch<React.SetStateAction<{
        visible: boolean;
        data: ContactProps;
    }>>
    onFinish?: (newId: number | undefined) => void;
}

export const NewContactModal = ({ data, setVisible, onFinish }: Props) => {

    const { name, token } = useSelector((state: any) => state.auth);

    const [form, setForm] = useState<ContactProps>(data);

    const [isEdit] = useState<boolean>(data.id !== undefined);

    const dispatch = useDispatch();

    const [fieldIncomplete, setFieldIncomplete] = useState({
        nickname: false,
    })

    const formValidations = () => {

        let existError: boolean = false;

        setFieldIncomplete({
            nickname: form.nickname.trim() === '',
        });

        if (form.nickname.trim() === '') {
            existError = true;


            return existError;

        }
    }


    const handleFormConfirm = async () => {

        if (!formValidations()) {

            let contactDocument: ContactProps = initialStateNewContact

            if (!isEdit) {

                contactDocument = { ...form, id: parseInt(getFullDate()) }

                setVisible({
                    visible: false,
                    data: initialStateNewContact
                });

            } else {

                contactDocument = form

                setVisible({
                    visible: false,
                    data: initialStateNewContact
                });

            }

            dispatch(startLoading())

            await startcreateUpdateContactQuery(contactDocument, token, dispatch, name, () => {

                setVisible({
                    visible: false,
                    data: initialStateNewContact
                });

                onFinish && onFinish(contactDocument.id ?? undefined);

                showMixinToast(isEdit ? 'Contacto actualizado' : 'Nuevo contacto creado', 'success')

            })

            setVisible({
                visible: false,
                data: initialStateNewContact
            });

            dispatch(finishLoading())


        } else {

            showModalMessage('Atención', 'Falta completar información.', 'error');

        }

    }

    return (

        <ShadowContainer>

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        {!isEdit ? 'Nuevo contacto' : form.nickname}
                    </Typography>

                    <Button
                        type="icon"
                        icon={require('../images/plus-primary-dark.png')}
                        onClick={() => {
                            setVisible({
                                visible: false,
                                data: initialStateNewContact
                            });
                        }}
                        style={{ transform: 'rotate(45deg)' }}
                    />


                </HeaderContainer>

                <RowContainer>

                    <Input
                        type="text"
                        label="Apodo"
                        value={form.nickname}
                        required
                        placeholder="Apodo de referencia del cliente"
                        onChange={(e) => {

                            setForm({
                                ...form,
                                nickname: e.target.value
                            })
                        }}
                    />

                    <Input
                        type="text"
                        label="Nombre y apellido (opcional)"
                        value={form.fullname}
                        placeholder="Nombre y apellido del cliente"
                        onChange={(e) => {

                            setForm({
                                ...form,
                                fullname: e.currentTarget.value
                            })
                        }}
                    />

                </RowContainer>

                <RowContainer>

                    <Input
                        type="text"
                        label="DNI (opcional)"
                        value={form.doc}
                        placeholder="Número de DNI"
                        onChange={(e) => {

                            setForm({
                                ...form,
                                doc: e.currentTarget.value
                            })
                        }}
                    />

                    <Input
                        type="text"
                        label="Ocupación (opcional)"
                        value={form.ocupation}
                        placeholder="Ocupación del cliente"
                        onChange={(e) => {

                            setForm({
                                ...form,
                                ocupation: e.currentTarget.value
                            })
                        }}
                    />

                </RowContainer>

                <RowContainer>

                    <Input
                        type="text"
                        label="Teléfono (opcional)"
                        value={form.phone}
                        placeholder="Número de teléfono"
                        onChange={(e) => {

                            setFieldIncomplete({ ...fieldIncomplete, });

                            setForm({
                                ...form,
                                phone: parseInt(e.currentTarget.value)
                            })
                        }}
                    />

                    <Input
                        type="text"
                        label="Correo electrónico (opcional)"
                        value={form.email}
                        placeholder="Correo del cliente"
                        onChange={(e) => {

                            setForm({
                                ...form,
                                email: e.currentTarget.value
                            })
                        }}
                    />

                </RowContainer>


                <FooterButtonsContainer
                    style={{
                        marginTop: 50,
                        paddingBottom: isIOS && isMobile ? 'env(safe-area-inset-bottom)' : '0'

                    }}
                    primaryButtonText={!isEdit ? 'Crear nuevo contacto' : 'Actualizar contacto'}
                    secondaryButtonText={'Cancelar'}
                    primaryDisabled={form.nickname === '' || form === data}
                    secondaryDisabled={false}
                    onClickPrimary={() => { handleFormConfirm() }}
                    onClickSecondary={() => {
                        setVisible({
                            visible: false,
                            data: initialStateNewContact
                        });
                    }}
                />



            </ModalCardContainer>


        </ShadowContainer>

    )
}

