import { css, styled } from "styled-components";
import { Typography } from "./Typography";
import { colors, device } from "../styles/stylesConfig";
import { Input } from "./Input";
import { Button } from "./atoms/Button";
import { useState } from "react";
import { showMixinToast } from "../utils/Alerts";
import { sortStock } from "../utils/Utilities";
import { StockProps, WorkStockProps } from "../interfaces/StockInterfaces";
import { WorkProps } from "../interfaces/WorkInterfaces";
import { useSelector } from "react-redux";
import { LoadingComponent } from "./LoadingComponent";
import { initialStateNewWorkStock } from "../utils/Jsons";

const SelectedStockItemContainer = styled.div<{ custom: string }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid ${colors.grey_normal};
    padding-left: 10px;
    padding-right: 10px;

    &:last-of-type {
        border-bottom: 1px solid transparent;
    }

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        color: ${colors.grey_dark};
    }

    @media ${device.sm} {
        ${props => props.custom === 'true' && css`
            flex-direction: column;
            margin-top: 20px;
            padding-bottom: 30px;
            padding-left: 0px;
            padding-right: 0px;
        `}
    }
 
`

const SelectedStockData = styled.div<{ custom: string, showamounts: string }>`
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & > h1 {
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${colors.grey};
        margin-top: 5px;
    }

    @media ${device.sm} {
        ${props => props.custom === 'true' && css`
            flex-direction: column;
            align-items: center;
            justify-content: center;

        `}
    }
`

const SelectedStockNameContainer = styled.div<{ custom: string }>`
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & > h1 {
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${colors.grey};
        margin-top: 5px;
    }

    & > h1:first-of-type {
        color: ${colors.grey};
        color: ${colors.grey_dark};
        margin-top: 0px;
    }

    @media ${device.sm} {
        ${props => props.custom === 'true' && css`
            flex-direction: row;
            align-items: center;
            justify-content: center;

            & > h1:last-of-type {
                margin-top: 0px;
                margin-left: 10px;
            }
        `}
    }
`

const SelectedStockActions = styled.div<{ custom: string }>`
    display: flex;
    flex-direction: row;

    & > div {
        margin-right: 20px;
    }

    @media ${device.sm} {
        ${props => props.custom === 'true' && css`
            margin-top: 40px;

            & > div {
                margin-right: 20px;
            }
        `}
    }

    @media ${device.xs} {
        ${props => props.custom === 'true' && css`
        flex-direction: column;

            & > div {
                width: 100% !important;
                margin-right: 0px;
                margin-bottom: 40px;
            }
        `}
    }
`

interface Props {
    showAmounts: boolean;
    showDelete: boolean;
    disabled: boolean;
    extension?: boolean;
    data: StockProps;
    form: WorkProps;
    setForm: React.Dispatch<React.SetStateAction<WorkProps>>;
}

export const SelectedStockItem = ({ showAmounts, showDelete, disabled, data: stockProduct, extension, form, setForm }: Props) => {

    const { works: worksList, checkingStock, checkingWorks } = useSelector((state: any) => state.srandamios);

    const { id, product, description, totalAmount } = stockProduct;

    const [customSelectedAmount, setcustomSelectedAmount] = useState(false);

    const getStockProductAmountInWork = () => {

        let productValue: WorkStockProps = initialStateNewWorkStock

        if (!checkingWorks && !checkingStock) {

            if (extension) {

                form.extension?.stock.find((product) => { if (id === product.id) productValue = product })

            } else {

                form.stock.find((product) => { if (id === product.id) productValue = product })

            }

        }

        return productValue.amount
    }

    const setAvailableAmount = () => {

        let amountCounter = 0

        if (!checkingWorks && !checkingStock) {

            worksList.forEach((value: WorkProps) => {

                return value.stock.find((product) => { if (id === product.id && (value.state.name === 'Para entregar' || value.state.name === 'Para retirar')) { amountCounter += product.amount }; })
            })

        }

        return amountCounter

    }

    return (

        <SelectedStockItemContainer key={id} custom={customSelectedAmount.toString()}>

            <SelectedStockData custom={customSelectedAmount.toString()} showamounts={showAmounts.toString()}>

                <SelectedStockNameContainer custom={customSelectedAmount.toString()}>

                    <Typography size="14px">
                        {product}
                    </Typography>

                    <Typography size="12px">
                        {description}
                    </Typography>

                </SelectedStockNameContainer>

                {
                    showAmounts && (
                        checkingStock || checkingWorks ? (
                            <LoadingComponent />
                        ) : (
                            <Typography size="12px">{`Disponibles ${totalAmount - setAvailableAmount()} de ${totalAmount} totales`}</Typography>
                        )

                    )
                }

            </SelectedStockData>

            <SelectedStockActions custom={customSelectedAmount.toString()}>

                <Input
                    label="Cantidad"
                    type="select"
                    readOnly={disabled}
                    value={customSelectedAmount ? 'Indicar cantidad' : getStockProductAmountInWork()}

                    onChange={(e) => {

                        if (e.target.value === 'Indicar cantidad') {
                            setcustomSelectedAmount(true);

                        } else {
                            setcustomSelectedAmount(false);
                        }

                        let stockFiltered = form.stock.filter((value) => { return value.id !== id });

                        if (e.target.value === 'Indicar cantidad') {
                            stockFiltered = [...stockFiltered, { id, amount: 1, toDeliverRetire: null }];
                        } else {
                            if (parseInt(e.target.value) !== 0) {
                                stockFiltered = [...stockFiltered, { id, amount: parseInt(e.target.value), toDeliverRetire: null }];
                            }
                        }

                        setForm({ ...form, stock: sortStock(stockFiltered) as WorkStockProps[] });

                    }}
                    style={{ marginTop: 0, width: `${customSelectedAmount ? '170px' : '80px'}`, height: 40 }}
                >
                    <option key={0} value={0}> - </option>
                    <option key={1} value={1}>1</option>
                    <option key={2} value={2}>2</option>
                    <option key={3} value={3}>3</option>
                    <option key={4} value={4}>4</option>
                    <option key={5} value={5}>5</option>
                    <option key={6} value={6}>6</option>
                    <option key={7} value={7}>7</option>
                    <option key={8} value={8}>8</option>
                    <option key={9} value={9}>9</option>
                    <option key={10} value={10}>10</option>
                    <option key={11} value={11}>11</option>
                    <option key={12} value={12}>12</option>
                    <option key={13} value={13}>13</option>
                    <option key={14} value={14}>14</option>
                    <option key={15} value={15}>15</option>
                    <option key={16} value={16}>16</option>
                    <option key={17} value={17}>17</option>
                    <option key={18} value={18}>18</option>
                    <option key={19} value={19}>19</option>
                    <option key={20} value={20}>20</option>
                    <option key={21} value={21}>21</option>
                    <option key={22} value={22}>22</option>
                    <option key={23} value={23}>23</option>
                    <option key={24} value={24}>24</option>
                    <option key={25} value={25}>25</option>
                    <option key={26} value={26}>26</option>
                    <option key={27} value={27}>27</option>
                    <option key={28} value={28}>28</option>
                    <option key={29} value={29}>29</option>
                    <option key={30} value={30}>30</option>
                    <option key={31} value={31}>31</option>
                    <option key={32} value={32}>32</option>
                    <option key={33} value={33}>33</option>
                    <option key={34} value={34}>34</option>
                    <option key={35} value={35}>35</option>
                    <option key={36} value={36}>36</option>
                    <option key={37} value={37}>37</option>
                    <option key={38} value={38}>38</option>
                    <option key={39} value={39}>39</option>
                    <option key={40} value={40}>40</option>
                    <option key={41} value={41}>41</option>
                    <option key={42} value={42}>42</option>
                    <option key={43} value={43}>43</option>
                    <option key={44} value={44}>44</option>
                    <option key={45} value={45}>45</option>
                    <option key={46} value={46}>46</option>
                    <option key={47} value={47}>47</option>
                    <option key={48} value={48}>48</option>
                    <option key={49} value={49}>49</option>
                    <option key={50} value={50}>50</option>
                    <option key={"Indicar cantidad"} value="Indicar cantidad">Indicar cantidad</option>
                </Input>

                {
                    customSelectedAmount && (
                        <Input
                            label="Cantidad"
                            type="number"
                            value={getStockProductAmountInWork()}
                            readOnly={disabled}

                            onChange={(e) => {

                                let stockFiltered = form.stock.filter((value) => { return value.id !== id });

                                if (e.target.value === '') {
                                    stockFiltered = [...stockFiltered, { id, amount: 1, toDeliverRetire: null }];
                                } else {
                                    stockFiltered = [...stockFiltered, { id, amount: parseInt(e.target.value), toDeliverRetire: null }];
                                }

                                setForm({ ...form, stock: sortStock(stockFiltered) as WorkStockProps[] });

                            }}
                            style={{ marginTop: 0, width: 75, height: 40 }}
                        />
                    )
                }

                {
                    showDelete && (
                        <Button
                            type="icon"
                            icon={require('../images/delete-primary-dark.png')}
                            onClick={() => {

                                let stockFiltered = form.stock.filter((value) => { return value.id !== id });

                                setForm({ ...form, stock: sortStock(stockFiltered) as WorkStockProps[] });

                                showMixinToast('Producto eliminado', 'success')

                            }}
                        />
                    )
                }

            </SelectedStockActions>

        </SelectedStockItemContainer>

    )
}
