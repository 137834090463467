import { styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Typography } from "./Typography";
import { Button } from "./atoms/Button";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { useState } from "react";
import { SelectedStockItem } from "./SelectedStockItem";
import { showMixinToast } from "../utils/Alerts";
import { WorkProps } from "../interfaces/WorkInterfaces";
import { StockProps } from "../interfaces/StockInterfaces";
import { useSelector } from "react-redux";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 45%;
    max-width: 90%;
    max-height: 75%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 50px;
    position: relative;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;

    @media ${device.lg} {
        width: 75%;
    } 


    @media ${device.md} {
        width: 95%;
        max-width: 95%;
        max-height: 80%;
    } 

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

const StockContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${colors.grey_dark};
        margin-top: 10px;
    }
`
interface Props {
    form: WorkProps;
    setForm: React.Dispatch<React.SetStateAction<WorkProps>>;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectStockListModal = ({ form, setForm, setVisible }: Props) => {

    const { stock: stockList } = useSelector((state: any) => state.srandamios);

    const [newForm, setNewForm] = useState(form);

    return (

        <ShadowContainer >

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        Stock
                    </Typography>

                    <Button
                        type="icon"
                        icon={require('../images/plus-primary-dark.png')}
                        onClick={() => { setVisible(false) }}
                        style={{ transform: 'rotate(45deg)' }}
                    />


                </HeaderContainer>

                <StockContainer>

                    {
                        stockList.length > 0 ? (

                            stockList.map((value: StockProps) => {

                                return (

                                    <SelectedStockItem
                                        showAmounts
                                        showDelete={false}
                                        disabled={false}
                                        data={value}
                                        form={newForm}
                                        setForm={setNewForm}
                                        key={value.id}
                                    />
                                )
                            })

                        )
                            : (
                                <Typography size="14px">
                                    No existen productos en el stock
                                </Typography>

                            )
                    }

                </StockContainer>

                <FooterButtonsContainer
                    style={{ marginTop: 50 }}
                    primaryButtonText={form.stock !== newForm.stock ? `Agregar productos ${newForm.stock.length > 0 ? `(${newForm.stock.filter((value) => { return value.amount !== -1 }).length})` : ''}` : 'Sin cambios'}
                    secondaryButtonText={'Cerrar'}
                    primaryDisabled={newForm.stock.length === 0 || form.stock === newForm.stock}
                    secondaryDisabled={false}
                    onClickPrimary={() => {
                        setForm({ ...newForm, stock: newForm.stock.filter((value) => { return value.amount !== -1 }) });
                        showMixinToast('Productos actualizados', 'success')
                        setVisible(false);
                    }}
                    onClickSecondary={() => {
                        setVisible(false);
                    }}
                />

            </ModalCardContainer>


        </ShadowContainer>

    )
}
