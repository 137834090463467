import axios from "axios"
import { finishLoading, setError } from "../actions/ui"
import { endpoints } from "../helpers/FirebaseHelper"
import { ContactProps } from "../interfaces/ContactInterfaces"

export const startcreateUpdateContactQuery = async (body: ContactProps, token: string, dispatch: any, name?: string, handleFinish?: () => void) => {

    await axios.post(`${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.createContact}`,
        { collection: "Contacts", id: body.id?.toString(), doc: body },
        { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {

            dispatch(finishLoading())

            handleFinish && handleFinish();

        }).catch(async (e) => {

            dispatch(finishLoading())

            let msg = ''

            if (e.response !== undefined) {

                if (e.response.data.trace?.includes('auth/id-token-expired')) {

                    msg = "Estuviste mucho tiempo inactivo y se venció tu sesión. " +
                        "Presioná el botón 'OK' para actualizar y seguir gestionando tus tareas."

                } else {
                    msg = e.response.data.message
                }

            } else {
                msg = 'Ha ocurrido un error.'
            }

            console.error({ msg, error: e });

            await axios.post(
                `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}${endpoints.sendEmail}`, {
                from: 'ERROR SR ANDAMIOS',
                to: process.env.REACT_APP_SEND_EMAIL_TO,
                name: 'Yo',
                phone: '',
                text: { name, msg, error: JSON.stringify(e, null, 2) }
            })

            dispatch(setError(msg));


        })


}
