
export const types = {

    login: '[Auth] Login',
    logout: '[Auth] Logout',

    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',

    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',

    uiOpenMenu: '[UI] Open Menu',
    uiCloseMenu: '[UI] Close Menu',

    uiOpenNotifications: '[UI] Open Notifications',
    uiCloseNotifications: '[UI] Close Notifications',

    srAndamiosSetWorks: '[SR Andamios] Set Works',
    srAndamiosRemoveWorks: '[SR Andamios] Remove Works',

    setCheckingSrAndamiosWorks: '[SR Andamios] Set Checking Works',
    removeCheckingSrAndamiosWorks: '[SR Andamios] Remove Checking Works',

    srAndamiosSetStock: '[SR Andamios] Set Stock',
    srAndamiosRemoveStock: '[SR Andamios] Remove Stock',

    setCheckingSrAndamiosStock: '[SR Andamios] Set Checking Stock',
    removeCheckingSrAndamiosStock: '[SR Andamios] Remove Checking Stock',

    srAndamiosSetContacts: '[SR Andamios] Set Contacts',
    srAndamiosRemoveContacts: '[SR Andamios] Remove Contacts',

    setCheckingSrAndamiosContacts: '[SR Andamios] Set Checking Contacts',
    removeCheckingSrAndamiosContacts: '[SR Andamios] Remove Checking Contacts',

    srAndamiosSetMovements: '[SR Andamios] Set Movements',
    srAndamiosRemoveMovements: '[SR Andamios] Remove Movements',

    setCheckingSrAndamiosMovements: '[SR Andamios] Set Checking Movements',
    removeCheckingSrAndamiosMovements: '[SR Andamios] Remove Checking Movements',
}