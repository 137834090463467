
export const endpoints = {
    createUser: '/createUser',
    updateFullname: '/updateFullnameUser',
    createWork: '/createNewWork',
    createStock: '/createNewStock',
    deleteStock: '/deleteStock',
    createMovement: '/createMovement',
    deleteMovement: '/deleteMovement',
    createContact: '/createNewContact',
    deleteContact: '/deleteContact',
    sendEmail: '/sendEmail',
}

