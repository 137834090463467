import { styled } from "styled-components";
import { colors, device } from "../styles/stylesConfig";
import { Typography } from "./Typography";
import { Button } from "./atoms/Button";
import { FooterButtonsContainer } from "./FooterButtonsContainer";
import { useState } from "react";
import { showMixinToast } from "../utils/Alerts";
import { useDispatch, useSelector } from "react-redux";
import { ContactProps } from "../interfaces/ContactInterfaces";
import { StoreProps } from "../interfaces/ReducerInterfaces";
import { SelectedContactItem } from "./SelectedContactItem";
import { finishLoading, startLoading } from "../actions/ui";
import { startcreateUpdateContactQuery } from "../queries/ContactQueries";
import { WorkProps } from "../interfaces/WorkInterfaces";
import { getWorkContact } from "../utils/Utilities";
import { initialStateNewContact, initialStateNewWork } from "../utils/Jsons";
import { startcreateUpdateWorkQuery } from "../queries/WorkQueries";
import { NewContactModal } from "./NewContactModal";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    backdrop-filter: blur(5px);
`

const ModalCardContainer = styled.div`
    width: 60%;
    max-width: 90%;
    max-height: 75%;
    box-shadow: 0px 3px 6px #00000026;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 50px;
    position: relative;
    background-color: white;
    position: relative;
    overflow-y: scroll;
    white-space: nowrap;

    @media ${device.lg} {
        width: 75%;
    } 


    @media ${device.md} {
        width: 95%;
        max-width: 95%;
        max-height: 80%;
    } 

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

const StockContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    & > h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${colors.grey_dark};
        margin-top: 10px;
    }
`
interface Props {
    data: WorkProps;
    setVisible: React.Dispatch<React.SetStateAction<{
        visible: boolean;
        data: WorkProps;
    }>>
    onFinish?: (newId: number | undefined) => void;
}

export const SelectContactListModal = ({ data, setVisible, onFinish }: Props) => {

    const { contacts } = useSelector((state: StoreProps) => state.srandamios);

    const { name, token } = useSelector((state: StoreProps) => state.auth);

    const [form, setForm] = useState<ContactProps | null>(getWorkContact(contacts, data.clientId ?? 0) ?? null);

    const dispatch = useDispatch();

    const [newContactModal, setNewContactModal] = useState<{ visible: boolean, data: ContactProps }>({
        visible: false,
        data: initialStateNewContact
    });


    return (

        <ShadowContainer >

            <ModalCardContainer>

                <HeaderContainer>

                    <Typography
                        size='28px'
                        bold
                    >
                        Contactos
                    </Typography>

                    <Button
                        type="icon"
                        icon={require('../images/plus-primary-dark.png')}
                        onClick={() => { setVisible({ visible: false, data: initialStateNewWork }) }}
                        style={{ transform: 'rotate(45deg)' }}
                    />


                </HeaderContainer>

                <StockContainer>

                    {
                        contacts.length > 0 ? (

                            contacts.map((value: ContactProps) => {

                                return (

                                    <SelectedContactItem
                                        showAmounts
                                        showDelete={false}
                                        disabled={false}
                                        data={value}
                                        key={value.id}
                                        form={form}
                                        setForm={setForm}
                                    />
                                )
                            })

                        )
                            : (
                                <Typography size="14px">
                                    No existen contactos cargados
                                </Typography>

                            )
                    }

                </StockContainer>

                <FooterButtonsContainer
                    style={{ marginTop: 50 }}
                    primaryButtonText={form === getWorkContact(contacts, data.clientId ?? 0) ? 'Seleccione otro contacto' : `Seleccionar nuevo contacto`}
                    secondaryButtonText={'Cerrar'}
                    terciaryButtonText={'Crear nuevo contacto'}

                    primaryDisabled={form === getWorkContact(contacts, data.clientId ?? 0)}
                    secondaryDisabled={false}
                    onClickPrimary={async () => {

                        dispatch(startLoading())

                        await startcreateUpdateWorkQuery({ ...data, clientId: form?.id ?? undefined }, token!, dispatch, name!, () => {

                            onFinish && onFinish(form?.id ?? undefined);

                            showMixinToast('Contacto actualizado', 'success');

                        })

                        setVisible({
                            visible: false,
                            data: initialStateNewWork
                        });

                        dispatch(finishLoading())

                        showMixinToast('Contacto actualizado', 'success')

                        setVisible({ visible: false, data: initialStateNewWork });
                    }}
                    onClickSecondary={() => {
                        setVisible({ visible: false, data: initialStateNewWork });
                    }}
                    onClickTerciary={() => {
                        setNewContactModal({ visible: true, data: initialStateNewContact });
                    }}
                />

            </ModalCardContainer>

            {
                newContactModal.visible && (
                    <NewContactModal
                        data={newContactModal.data}
                        setVisible={setNewContactModal}
                    />
                )
            }

        </ShadowContainer>

    )
}
