import { styled } from "styled-components";
import { colors, device } from "../../styles/stylesConfig";
import { CSSProperties } from "react";

interface Props {
    status: boolean
    style?: CSSProperties
}

const Status = styled.div` 
    height: 16px;
    width: 16px;
    border-radius: 100px;
    z-index: 1;
    position: absolute;
    left: 5px;
    box-shadow: 0px 0px 6px #00000026;

    &:hover {
        cursor: pointer;

    }

        // Tooltip
    
        span {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 5px 15px;
        opacity: 0;
        pointer-events: none;
        transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
        background-color: ${colors.grey_dark};
        z-index: 1;
        color: ${colors.grey_light};
        border-radius: 8px;
        scale: 0;
        transform-origin: 0 0;
        font-weight: 400;
        font-size: 16px;
        box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
        white-space: nowrap;
        }

        span::before {
            position: absolute;
            content: "";
            height: 0.6em;
            width: 0.6em;
            bottom: -0.2em;
            left: 50%;
            transform: translate(-50%) rotate(45deg);
            background-color: ${colors.grey_dark};
        }

        &:hover span {
            top: -250%;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            scale: 1;
            animation: shake 0.5s ease-in-out both;
        }

    // Tooltip
`

export const PaymentStatus = ({ status, style }: Props) => {

    return (
        <Status
            onClick={(e: any) => e.stopPropagation()}
            style={{
                backgroundColor: status ? colors.ok : colors.danger,
                ...style
            }}
        >
            <span>{status ? 'Pagó' : 'No pagó'}</span>
        </Status>
    )
}
